import React, { useState, useEffect } from 'react';

export default function SingleInputRangeTime(props) {
  const [fromValue, setFromValue] = useState(Number(props.min));
  const [toValue, setToValue] = useState(Number(props.max));

  const [initialFromValue, setInitialFromValue] = useState(Number(props.min));
  const [initialToValue, setInitialToValue] = useState(Number(props.max));

  const [status, setStatus] = useState(props.reset);

  const resetToInitialValues = () => {
    setFromValue(initialFromValue);
    setToValue(initialToValue);
  };

  useEffect (() => {
    if (!props.valor.includes("facilitadores")) {
      setFromValue(initialFromValue);
      setToValue(initialToValue);
    }
  }, [props.valor])

  useEffect (() => {
    props.reset;
  }, [props.reset])

  useEffect (() => {
    if (props.reset == true) {
      resetToInitialValues()
      setStatus(false)
    }
  }, [props.reset])


  useEffect(() => {
    handleSliders();
  }, [fromValue]);

  const handleSliders = () => {
    const [from] = getSliderValues();


    setToValue(from + 1);
    setLabels(from);
    // fillSlider(from);
  };

  const getSliderValues = () => {
    const from = parseInt(fromValue);
    // const to = parseInt(toValue);
    return [from];
  };

  const setLabels = (from) => {
    const fromLabelElement = document.querySelector(`.range-slider__value--min-${props.id}`);
      fromLabelElement.style.left = (((from - 1) / props.max) * 100) + '%';
      fromLabelElement.innerText = from;

  };

  // const fillSlider = (from) => {
  //   const fromSliderElement = document.querySelector(`.range-slider__slider--from-${props.id}`);
  //   // const toSliderElement = document.querySelector(`.range-slider__slider--to-${props.id}`);
  //   // const controlSliderElement = toSliderElement;

  //   // const rangeDistance = toSliderElement.max - toSliderElement.min;
  //   const fromPosition = fromSliderElement.value - toSliderElement.min;
  //   // const toPosition = toSliderElement.value - toSliderElement.min;
  //   controlSliderElement.style.background = `linear-gradient(
  //     to right,
  //     transparent 0%,
  //     transparent ${(fromPosition / rangeDistance) * 100}%,
  //     #22AA96 ${(fromPosition / rangeDistance) * 100}%,
  //     transparent 100%)`;
  // };

  return (
    <>
      <div className={`range-slider range-slider--dual-${props.id}`}>
      <div className={`range-slider__indicators-${props.id}`}>
        <div className={`range-slider__value range-slider__value--min-${props.id}`}></div>
      </div>
      <input
        className={`range-slider__slider range-slider__slider--from-${props.id}`}
        type="range"
        value={fromValue}
        min={Number(props.min)}
        max={Number(props.max)}
        onChange={(e) => {
          setFromValue(e.target.value);
          props.onMinChange(e.target.value);
        }}
        aria-label={props.viewName}
      />
      <div className={`range-slider__scale-${props.id}`}>
        {/* Scale elements */}
      </div>
    </div>
    <div className={`minMax-${props.id}`}>
      <span>Min.</span>
      {/* <span>Máx.</span> */}
    </div>
    </>
  );
}
