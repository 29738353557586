import classNames from 'classnames/bind';
import styles from './SelectedFilters.module.scss';
import Image from 'next/image';
let cx = classNames.bind(styles);

import XIcon from '../../public/svg/x.svg';

export default function SelectedFilters(props) {
  
  const handleFilterClick = (filterName) => {
    props.onFilterSelect(filterName);
  };

  return (
    <>
      <button className={cx('buttonFilter')} onClick={() => handleFilterClick(props.name)}>
        <span>{props.name}</span>
        
        <Image
          priority
          src={XIcon}
          alt="Imagem principal"
          className='x'
        />
      </button>
    </>
  );
}
