import Script from 'next/script'
// import { GA_TRACKING_ID } from '../../utils/gtag'

export default function Analytics(props) {
  return (
    <>
      <Script
        id={props.id}
        strategy="beforeInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=G-NWJMTBF1VE`}
      />
      <Script
        id={props.trakingID}
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{__html: `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${props.trakingID}', {page_path: 'window.location.pathname',});`}}
      />
    </>
  )
}