import { useQuery, gql } from '@apollo/client';
import * as MENUS from '../constants/menus';
import Image from 'next/image';
import Head from 'next/head'
import Link from 'next/link'
import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

//Components
import { Header, Footer, Main, NavigationMenu, SEO, } from '../components';
import { Filters } from '../components/Filters';
import { HeaderFilter } from '../components/HeaderFilter';
import { Checkbox } from '../components/Checkbox';
import { KeyWords } from '../components/KeyWords';
import { DualInputRangeTime } from '../components/DualInputRangeTime';
import { SingleInputRangeTime } from '../components/SingleInputRangeTime';
import { CardPosts } from '../components/CardPosts';
import { SelectedFilters } from '../components/SelectedFilters';
import { FeaturedImage } from '../components/FeaturedImage';

//Image
import HeaderImage from '../public/images/hero-element-es.png';
import HeaderImagePT from '../public/images/hero-element-ptbr.png';

//Icons
import ArrowDown from '../public/svg/arrow-down.svg';
import XIcon from '../public/svg/x.svg';
import XIconBig from '../public/svg/xBig.svg';
import Tag from '../public/svg/tag.svg';
import Pin from '../public/svg/map-pin.svg';
import Circles from '../public/svg/circles.svg';
import Clock from '../public/svg/clock-hour-4.svg';
import Git from '../public/svg/git-branch.svg';
import Plus from '../public/svg/plus.svg';
import Minus from '../public/svg/menos.svg';
import SearchIcon from '../public/svg/icon-search.svg';
import BarChart from '../public/svg/icon-bar-chart.svg';
import TargetArrow from '../public/svg/target-arrow.svg';
// import Info from '../public/svg/info.svg';
import UsersGroup from '../public/svg/users-group.svg';
// import Educx from '../public/svg/icone-educx.svg';
import Educx from '../public/svg/logo.svg';
import Cubus from '../public/svg/cubus.svg';
import cardIcon from '../public/svg/iconCard.svg';
import { set } from 'firebase/database';
import { InputText } from '../components/InputText';
import { TooltipComponent } from '../components/TooltipComponent';


// const GET_NEXT_TAGS = gql`
//   query NextTags($after: String!){
//     tags(first: 100, after: $after) {
//       edges {
//         node {
//           id
//           name
//           slug
//         }
//       }
//       pageInfo {
//         endCursor
//         hasNextPage
//       }
//     }
//   }
// `;


export default function Component({ initialPosts, initialTags }) {
  // loading Posts
  const [loading, setLoading] = useState(true);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [linguagemSelecionada, setLinguagemSelecionada] = useState("PT");
  const [filterIsActive, setFilterIsActive] = useState("disable");
  const [limitKeywords, setLimitKeywords] = useState(10)
  const [searchTerm, setSearchTerm] = useState('');
  const [isReversed, setIsReversed] = useState(false);
  const [alphabetical, setAlphabetical] = useState(true);
  const [publication, setPublication] = useState(false);
  const [filteredValues, setFilteredValues] = useState([]);
  const [filterSettings, setFilterSettings] = useState({ category: [], tipodeatividade: [], formato: [], finalidadesPedagogicas: [], participantesMinimo: '', participantesMaximo: '', tempoMinimo: '', tempoMaximo: '', facilitadoresMinimo: '', facilitadoresMaximo: '', search: "", searchTerm: "", keywords: [] });
  const [selectedFilterTypes, setSelectedFilterTypes] = useState({});
  const [key, setKey] = useState([]);
  const [minValue, setMinValue] = useState('');
  const [maxValue, setMaxValue] = useState('');
  const [minValueTempo, setMinValueTempo] = useState('');
  const [maxValueTempo, setMaxValueTempo] = useState('');
  const [minValueFacilitadores, setMinValueFacilitadores] = useState(1);
  const [maxValueFacilitadores, setMaxValueFacilitadores] = useState(12);
  const [resetFilter, setResetFilter] = useState(false);
  const [filteredPosts, setFilteredPosts] = useState();
  const [mostrarMais, setMostrarMais] = useState(false);
  const [participantesStatus, setParticipantesStatus] = useState(false);
  const [tempoStatus, setTempoStatus] = useState(false);
  const [facilitadorStatus, setFacilitadorStatus] = useState(false);

  const [participantesMax, setParticipantesMax] = useState(null);
  const [tempoMax, setTempoMax] = useState(null);
  const [facilitacaoMax, setFacilitadoresMax] = useState(null);

  const [tagsQuery, setTagsQuery] = useState([]);
  const [nextCursor, setNextCursor] = useState("YXJyYXljb25uZWN0aW9uOjcxMQ==");
  const [hasNextPage, setHasNextPage] = useState(true);
  const [carregarMais, setCarregarMais] = useState(true);

  // Lógica nova
  const [participantesHabilitado, setParticipantesHabilitado] = useState(false);
  const [tempoHabilitado, setTempotesHabilitado] = useState(false);
  const [facilitadoresHabilitado, setFacilitadoresHabilitado] = useState(false);

  const router = useRouter();
  const { search, keyword } = router.query;

  const maxAmount = 200;
  //Getting data from GraphQL
  const { data, fetchMore, loading: queryLoading } = useQuery(GET_POSTS, {
    variables: {
      language: String(linguagemSelecionada),
    },
    context: {
      maxAmount,
    },
    onCompleted: () => {
      setFilteredPosts(data.posts.nodes);
      setLoading(false); // Desativa o estado de carregamento quando a consulta termina
    },
  });

  useEffect(() => {
    if (initialPosts && initialTags) {
      setLoading(false); // Se os dados iniciais já estiverem presentes, desativa o estado de carregamento
    }
  }, [initialPosts, initialTags]);

  // console.log(data);
  const tags = data?.tags.edges;
  const posts = data?.posts.nodes;


  let esconder = false

  useEffect(() => {

    if (hasNextPage == true && data && data.tags && data.tags.edges) {
      setTagsQuery(prevTags => [...prevTags, ...data.tags.edges.map(edge => edge.node)]);
      setNextCursor(data.tags.pageInfo.endCursor);
      setHasNextPage(data?.tags.pageInfo.hasNextPage);
    }
  }, [data]);

  const loadMoreTags = () => {
    // console.log(hasNextPage);

    if (hasNextPage == false) {
      if (limitKeywords == 10) {
        setLimitKeywords(100)
      } else if (limitKeywords > 10 && limitKeywords <= tagsQuery.length) {
        setLimitKeywords(limitKeywords + 100)
      } else {
        setLimitKeywords(10);
        setMostrarMais(false)
      }
    } else {
      setCarregarMais(true)
      if (limitKeywords == 10) {
        setLimitKeywords(100)
      } else {

        if (hasNextPage == true) {
          fetchMore({
            variables: {
              tagsCursor: nextCursor,
            },
          }).then(updatedData => {
            const newTags = updatedData.data.tags.edges.map(edge => edge.node);
            setTagsQuery(prevTags => [...prevTags, ...newTags]);
            setNextCursor(updatedData.data.tags.pageInfo.endCursor);
            setHasNextPage(updatedData.data.tags.pageInfo.hasNextPage);
          });
          if (limitKeywords == 10) {
            setLimitKeywords(100);
            setMostrarMais(true)
          } else {
            setLimitKeywords(limitKeywords + 100)
          }
        } else {
          setLimitKeywords(limitKeywords + 100);
          setMostrarMais(true)
        }
      }
    }
  };

  useEffect(() => {
    // console.log("tamanho das tags", tagsQuery.length );
    if (hasNextPage) {
      setCarregarMais(true)
    } else {
      if (limitKeywords >= 10 && limitKeywords <= tagsQuery.length) {
        setCarregarMais(true)
      } else if (limitKeywords >= tagsQuery.length) {
        setCarregarMais(false)
      }
    }
  }, [limitKeywords, hasNextPage])


  useEffect(() => {
    let maxParticipantes = 0;
    let maxTempo = 0;

    // Verifique cada post para encontrar o máximo de participantes
    posts?.forEach((post) => {
      const participantesDoPost = post.VulpeModuloDados.participantesMaximo;

      if (participantesDoPost > maxParticipantes) {
        maxParticipantes = participantesDoPost;
      }
    });

    posts?.forEach((post) => {
      const tempoDoPost = post.VulpeModuloDados.tempoMaximo;

      if (Number(tempoDoPost) > maxTempo) {
        maxTempo = tempoDoPost;
      }
    });

    // Defina o estado com o valor máximo encontrado
    setParticipantesMax(maxParticipantes);
    setTempoMax(maxTempo);
    setFacilitadoresMax(12)

  }, [posts]);

  // console.log("Participantes máximo: ", participantesMax);
  // console.log("Tempo máximo: ", tempoMax);

  // console.log(tags);

  const handleActiveModalFilter = () => {
    setIsModalVisible(true);
  }

  const handleActiveModalFilterDesk = () => {
    if (window.innerWidth < 769) {
      setIsModalVisible(true);
    }
  }


  useEffect(() => {
    if (search && search !== '') {
      const anchorElement = document.getElementById('busca');
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: 'smooth' });
      }
      handleAdvancedFilter(null, { keyValue: 'search', filterValue: search })
      // handleAdvancedFilter(null, { keyValue: 'keywords', filterValue: search })
    } else if (keyword && keyword !== '') {
      const anchorElement = document.getElementById('busca');
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: 'smooth' });
      }
      handleAdvancedFilter(null, { keyValue: 'keywords', filterValue: keyword })
    }
  }, [search, keyword])

  // Funções de retorno de chamada para atualizar os valores mínimos e máximos
  const handleMinChangeParcipantes = (value) => {
    setMinValue(value);
    setParticipantesHabilitado(true);
  };

  const handleMaxChangeParcipantes = (value) => {
    setMaxValue(value);
    setParticipantesHabilitado(true);
  };

  const handleMinChangeTempo = (value) => {
    setMinValueTempo(value);
    setTempotesHabilitado(true);
  };

  const handleMaxChangeTempo = (value) => {
    setMaxValueTempo(value);
    setTempotesHabilitado(true);

  };

  const handleMinChangeFacilitadores = (value) => {
    setMinValueFacilitadores(value);
    setFacilitadoresHabilitado(true);
  };

  /* const handleMaxChangeFacilitadores = (value) => {
    setMaxValueFacilitadores(value);
  }; */

  useEffect(() => {
    /* ancora */
    if (participantesStatus == true) {
      const novaString = `Participantes: ${minValue}-${maxValue}`;

      const index = filteredValues.findIndex(valor => valor.startsWith("Participantes:"));

      if (index !== -1) {
        // Se o valor existir, substitua-o pelo novo valor
        const novoArray = [...filteredValues];
        novoArray[index] = novaString;
        setFilteredValues(novoArray);

      } else {
        // Caso contrário, adicione a nova string ao array
        if (minValue != '' && maxValue != '') {
          setFilteredValues([...filteredValues, novaString]);
        }
      }

    }
    setParticipantesStatus(true);

  }, [minValue, maxValue]);


  useEffect(() => {

    if (tempoStatus == true) {
      const novaStringTempo = `Tempo: ${minValueTempo}min-${maxValueTempo}min`;

      const index = filteredValues.findIndex(valor => valor.startsWith("Tempo:"));

      if (index !== -1) {
        // Se o valor existir, substitua-o pelo novo valor
        const novoArray = [...filteredValues];
        novoArray[index] = novaStringTempo;
        setFilteredValues(novoArray);
      } else {
        // Caso contrário, adicione a nova string ao array
        if (minValueTempo != '' && maxValueTempo != '') {
          setFilteredValues([...filteredValues, novaStringTempo]);
        }
      }
    }

    setTempoStatus(true);

  }, [maxValueTempo, minValueTempo]);


  useEffect(() => {
    if (facilitadorStatus == true) {

      const novaStringFacilitadores = `Facilitadores: ${minValueFacilitadores}+`;

      const index = filteredValues.findIndex(valor => valor.startsWith("Facilitadores:"));

      if (index !== -1) {
        // Se o valor existir, substitua-o pelo novo valor
        const novoArray = [...filteredValues];
        novoArray[index] = novaStringFacilitadores;
        setFilteredValues(novoArray);
      } else {
        // Caso contrário, adicione a nova string ao array
        setFilteredValues([...filteredValues, novaStringFacilitadores]);
      }
    }

    setFacilitadorStatus(true);

  }, [minValueFacilitadores]);

  //Modal 
  // const handleIconHover = () => {
  //   setIsModalVisible(true);
  // };

  // const handleModalClose = () => {
  //   setIsModalVisible(false);
  // };

  // const handleMoreKeywords = () => {
  //   if (limitKeywords == 10) {
  //     setLimitKeywords(300);
  //     setMostrarMais(true)
  //   } else {
  //     setLimitKeywords(10);
  //     setMostrarMais(false)
  //   }
  // }

  //Alphabetical Filters
  const handleNewOrder = (event) => {
    if (isReversed == false) {
      const reversedData = [...filteredPosts].sort((a, b) => {
        // Compare os títulos de a e b em ordem alfabética decrescente
        return b.title.localeCompare(a.title);
      });
      setFilteredPosts(reversedData)
      setIsReversed(true)
      setAlphabetical(false)
      setPublication(false)
    }
  }

  const handleAlphabeticalOrder = (event) => {
    if (alphabetical == false) {
      const AlphabeticalOrder = [...filteredPosts].sort((a, b) => {
        // Compare os títulos de a e b em ordem alfabética
        return a.title.localeCompare(b.title);
      });
      setFilteredPosts(AlphabeticalOrder)
      setAlphabetical(true)
      setIsReversed(false)
      setPublication(false)

    }
  }

  const handleOrderPublication = (event) => {
    if (publication == false) {
      const PublicationOrder = [...filteredPosts].sort((a, b) => new Date(b.date) - new Date(a.date));
      setFilteredPosts(PublicationOrder)
      setAlphabetical(false)
      setIsReversed(false)
      setPublication(true)
    }
  }

  //Input Search Filter 
  const handleSearchChange = (event) => {
    const searchText = event.target.value;
    setSearchTerm(searchText)
    handleAdvancedFilter(null, { keyValue: 'searchTerm', filterValue: event.target.value })

    // const anchorElement = document.getElementById('busca');
    // if (anchorElement) {
    //   anchorElement.scrollIntoView({ behavior: 'smooth' });
    // }
    // const filteredHeader = posts.filter((post) =>
    //   post.title.toLowerCase().includes(searchText.toLowerCase()) ||
    //   post?.tags.nodes.some((node) =>  node.name.includes(searchText) )
    // );
    // setFilteredPosts(filteredHeader);

  };

  const handleFilterChange = (filterValue) => {
    const anchorElement = document.getElementById('busca');
    if (anchorElement) {
      anchorElement.scrollIntoView({ behavior: 'smooth' });
    }
    const filteredHeader = posts.filter((post) =>
      post?.title.toLowerCase().includes(filterValue.toLowerCase()) ||
      post?.tags.nodes.some((node) => node.name.includes(filterValue.toLowerCase()))
    );
    setFilteredPosts(filteredHeader);

    // filterSettings.keywords.some((keyword) =>
    //   post?.tags.nodes.some((node) =>
    //     node.name.includes(keyword)
    //   ),
    // ),

  };

  useEffect(() => {
    setFilterSettings((prevSettings) => {
      const updatedSettings = { ...prevSettings };
      if (participantesHabilitado && minValue != "" && maxValue != "") {
        updatedSettings.participantesMinimo = Number(minValue);
        updatedSettings.participantesMaximo = Number(maxValue);
      }
      if (tempoHabilitado && minValueTempo != "" && maxValueTempo != "") {
        updatedSettings.tempoMinimo = Number(minValueTempo);
        updatedSettings.tempoMaximo = Number(maxValueTempo);
      }

      if (facilitadoresHabilitado) {
        updatedSettings.facilitadoresMinimo = Number(minValueFacilitadores);
        // updatedSettings.facilitadoresMaximo = Number(maxValueFacilitadores);
      }
      return updatedSettings;
    });

    if (participantesHabilitado && minValue != "" && maxValue != "") {
      if (!key.includes('participantes')) {
        setKey((prevKeys) => [...prevKeys, 'participantes']);
      }
    }

    if (tempoHabilitado && minValueTempo != "" && maxValueTempo != "") {
      if (!key.includes('tempo')) {
        setKey((prevKeys) => [...prevKeys, 'tempo']);
      }
    }

    if (facilitadoresHabilitado) {
      if (!key.includes('facilitadores')) {
        setKey((prevKeys) => [...prevKeys, 'facilitadores']);
      }
    }

  }, [minValue, maxValue, maxValueFacilitadores, minValueFacilitadores, maxValueTempo, minValueTempo]);

  const handleAdvancedFilter = (event, criteria) => {
    const { keyValue, filterValue } = criteria;

    console.log("Primeiros key: ", keyValue);
    console.log("Primeiros value: ", filterValue);

    if (typeof keyValue === "undefined") {
      if (filterValue.startsWith("Tempo")) {
        keyValue = "tempo";
      } else if (filterValue.startsWith("Participantes")) {
        keyValue = "participantes";
      } else if (filterValue.startsWith("Facilitadores")) {
        keyValue = "facilitadores";

      }
    }

    // console.log("KeyValue dps do if: ", keyValue);
    // Registre o tipo do filtro associado ao filtro selecionado
    setSelectedFilterTypes((prevFilterTypes) => ({
      ...prevFilterTypes,
      [filterValue]: keyValue,
    }));

    // Adicione a nova key à matriz de keys
    if (!key.includes(keyValue)) {
      setKey((prevKeys) => [...prevKeys, keyValue]);
    }
    // Atualize as configurações de filtro


    if (keyValue == 'participantes' || keyValue == "tempo" || keyValue == "facilitadores") {

      if (keyValue == 'participantes') {
        setMaxValue('');
        setMinValue('');
        console.log('Entrou no if do participantes');
      } else if (keyValue == 'tempo') {
        setMaxValueTempo('');
        setMinValueTempo('');
      }

      setFilteredValues((prevValues) => {
        // Verifique se filterValue já está presente no array
        const isValuePresent = prevValues.includes(filterValue);

        console.log('filterValue ', filterValue);
        if (isValuePresent) {
          if (filterValue.startsWith("Tempo")) {
            // keyValue = "tempo";
          } else if (filterValue.startsWith("Participantes")) {
            // keyValue = "participantes";
          } else if (filterValue.startsWith("Facilitadores")) {
            // console.log("Facilitadores true");
          }
        }

        // Se já estiver presente, remova-o, caso contrário, adicione-o
        const updatedValues = isValuePresent
          ? prevValues.filter((item) => item !== filterValue)
          : [...prevValues, filterValue];

        // Verifique se o array resultante está vazio e redefina a chave, se necessário
        if (updatedValues.length === 0) {
          // Se estiver vazio, redefina a chave para um array vazio
          setKey([]);
        } else {
          // Se não estiver vazio, verifique se há valores repetidos
          const hasDuplicates = new Set(updatedValues).size !== updatedValues.length;
          // Se houver valores repetidos, remova a chave
          if (hasDuplicates) {
            setKey([]);
          }
        }

        return updatedValues;
      });



      setKey((prev) => {
        const updatekey = prev.filter((item) => item !== keyValue);
        return updatekey;
      })
    } else {
      // Atualize o estado dos valores filtrados
      if (filterValue && keyValue != "searchTerm") {
        setFilteredValues((prevValues) => {
          // Verifique se filterValue já está presente no array
          const isValuePresent = prevValues.includes(filterValue);
          // console.log("condi", isValuePresent);
          if (isValuePresent) {
            if (filterSettings[keyValue].length === 1) {
              setKey((prev) => {
                const updatekey = prev.filter((item) => item !== keyValue);

                return updatekey;
              })
            }
          }

          // Se já estiver presente, remova-o, caso contrário, adicione-o
          const updatedValues = isValuePresent
            ? prevValues.filter((item) => item !== filterValue)
            : [...prevValues, filterValue];

          // Verifique se o array resultante está vazio e redefina a chave, se necessário
          if (updatedValues.length === 0) {
            // Se estiver vazio, redefina a chave para um array vazio
            setKey([]);
          } else {
            // Se não estiver vazio, verifique se há valores repetidos
            const hasDuplicates = new Set(updatedValues).size !== updatedValues.length;
            // Se houver valores repetidos, remova a chave
            if (hasDuplicates) {
              setKey([]);
            }
          }

          return updatedValues;
        });
      } /* else if (keyValue == "searchTerm") {
        
      } */
      setFilterSettings((prevSettings) => {
        const updatedSettings = { ...prevSettings };
        if (!updatedSettings[keyValue].includes(filterValue)) {
          updatedSettings[keyValue] = [filterValue, ...updatedSettings[keyValue]];
        } else {
          updatedSettings[keyValue] = updatedSettings[keyValue].filter((item) => item !== filterValue);
        }
        return updatedSettings;
      });
    }

    // console.log("filterSettings[key]: ", filterSettings);

  };

  useEffect(() => {
    const filterFunctions = {
      tipodeatividade: (post) =>
        filterSettings.tipodeatividade.some((modalidade) =>
          post?.VulpeModuloDados.tipodeatividade.includes(modalidade)
        ),
      formato: (post) =>
        filterSettings.formato.some((formato) =>
          post?.VulpeModuloDados.formato.includes(formato)
        ),

      finalidadesPedagogicas: (post) =>
        filterSettings.finalidadesPedagogicas.some((finalidadesPedagogicas) =>
          post?.VulpeModuloDados.finalidadesPedagogicas.includes(finalidadesPedagogicas)
        ),
      category: (post) =>
        filterSettings.category.some((category) =>
          post?.categories.edges[0].node.name.includes(category)
        ),

      participantes: (post) => {
        return (
          // Eu acho certo 
          (post?.VulpeModuloDados?.participantesMinimo >= filterSettings.participantesMinimo ||
            post?.VulpeModuloDados?.participantesMinimo == null) &&
          (post?.VulpeModuloDados?.participantesMaximo <= filterSettings.participantesMaximo ||
            post?.VulpeModuloDados?.participantesMaximo == 300)
        )
      },

      tempo: (post) => {
        return (
          (post?.VulpeModuloDados?.tempoMinimo >= filterSettings.tempoMinimo ||
            post?.VulpeModuloDados?.tempoMinimo == null) &&
          (post?.VulpeModuloDados?.tempoMaximo <= filterSettings.tempoMaximo ||
            post?.VulpeModuloDados?.tempoMaximo == 500)
        )
      },

      facilitadores: (post) => {
        return (
          filterSettings.facilitadoresMinimo >= post?.VulpeModuloDados?.facilitacao
        )
      },

      search: (post) => {
        const titleMatch = post?.title.toLowerCase().includes(search.toLowerCase());

        const keywordsMatch = post?.tags.nodes.some((node) =>
          node.name.includes(search.toLowerCase())
        );

        return titleMatch || keywordsMatch;

      },

      searchTerm: (post) => {
        const titleMatch = post?.title.toLowerCase().includes(searchTerm.toLowerCase())

        const keywordsMatch = post?.tags.nodes.some((node) =>
          node.name.includes(searchTerm.toLowerCase())
        );

        return titleMatch || keywordsMatch;
      },

      keywords: (post) =>
        filterSettings.keywords.some((keyword) =>
          post?.tags.nodes.some((node) =>
            node.name == keyword
          ),
        ),
    };


    // console.log("Keys: ", key);
    // console.log("Configurações do filtro: ", filterSettings);

    const filtered = posts?.filter((post) => {
      // Use as funções de filtro com base nas keys selecionadas
      return key.every((key) => {
        const filterFunction = filterFunctions[key];
        return filterFunction ? filterFunction(post) : true;
      });
    });

    // console.log('***', filtered)

    setFilteredPosts(filtered);

  }, [key, filterSettings, posts, search, searchTerm]);

  function resetCheckboxes() {
    const aside = document.getElementById('aside');
    const checkboxes = aside.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });

    const rangeInputs = aside.querySelectorAll('input[type="range"]');
    rangeInputs.forEach((range) => {
      range.value = range.getAttribute('min'); // Define o valor mínimo
    });
  }

  const handleRemoveFilters = () => {
    setFilterIsActive('disable');
    resetCheckboxes();
    setFilteredValues([]);
    setKey([]);
    setFilteredPosts(posts)
    setResetFilter(true)
    setFilterSettings({ category: [], tipodeatividade: [], formato: [], finalidadesPedagogicas: [], participantesMinimo: '', participantesMaximo: '', tempoMinimo: '', tempoMaximo: '', facilitadoresMinimo: '', facilitadoresMaximo: '', search: "", searchTerm: "", keywords: [] });

    setMinValue('');
    setMaxValue('');
    setMinValueTempo('');
    setMaxValueTempo('');
    setMinValueFacilitadores(1);
    setMaxValueFacilitadores(12);
    setTempoStatus(false);
    setParticipantesStatus(false);
    setFacilitadorStatus(false);

    setParticipantesHabilitado(false);
    setTempotesHabilitado(false);
    setFacilitadoresHabilitado(false);
  }


  useEffect(() => {
    setResetFilter(false)
  }, [resetFilter])


  const handleChangeLaguage = (e) => {
    setLinguagemSelecionada(e)
    setTagsQuery([]);
    // Dependendo da sua lógica de paginação, você pode querer também resetar o cursor e hasNextPage
    setNextCursor(null); // Ajuste para o valor inicial conforme sua lógica
    setHasNextPage(true);
  }

  const handleReturnName = (e) => {
    console.log(e);
  }

  /* Quando arrumar a questão da rota usar isso - tem que arrumar a lógica ainda */
  // useEffect(() => {
  //   router.push(`/${linguagemSelecionada == "PT" ? '' :linguagemSelecionada.toLowerCase()}`);
  // }, [linguagemSelecionada])

  // console.log('*** Posts Index', posts)

  return (
    <>
      <Head>
        <title>Home - Plataforma Cubus</title>
        <meta name="description" content="" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        <link href="https://fonts.googleapis.com/css2?family=Playfair+Display:wght@900&family=Roboto:wght@400;500;700&display=swap" rel="stylesheet" />
        <link rel="stylesheet" href="https://use.typekit.net/osb5xjt.css"></link>
      </Head>
      {/* <SEO
        title={siteTitle}
        description={siteDescription}
        imageUrl={featuredImage?.node?.sourceUrl}
      /> */}
      <Header
        // title={siteTitle}
        // description={siteDescription}
        // menuItems={primaryMenu}
        tipoPagina="home"
        onFilterChange={handleFilterChange}
        changeLaguageGeneral={handleChangeLaguage}
      />
      <div className="destaque"> {/* foto de fundo e elemento de rabisco */}
        <div className="firstColumn">
          {/* <span>Bem-vindos à</span> */}
          <div className='title'>
            <h1>{linguagemSelecionada == "ES" ? 'Plataforma ' : 'Plataforma '}
              <Image
                priority
                src={Cubus}
                alt="Cubus Icon"
                className='isisIcon'
              /></h1>
          </div>

          <div className="paragrafos">
            <p className='firstP'>{linguagemSelecionada == "ES" ? 'Aquí encontrará una variedad de métodos, prácticas, recursos y estrategias para ampliar su repertorio y crear actividades de aprendizaje más activas e innovadoras.' : 'Aqui você encontra uma variedade de métodos, práticas, recursos e estratégias para ampliar seu repertório e criar atividades de aprendizagem mais ativas e inovadoras.'}</p>
            <p className='p'>{linguagemSelecionada == "ES" ? 'La plataforma Cubus es un espacio de intercambio de procesos pedagógicos, con especial atención al desarrollo de habilidades transversales en el ámbito de la formación profesional y tecnológica, curado por el Departamento Nacional del Senac y de la OIT/Cinterfor.' : 'A plataforma Cubus é um espaço de compartilhamento de processos pedagógicos, com especial atenção ao desenvolvimento de competências transversais no âmbito da formação profissional e tecnológica, com curadoria do Departamento Nacional do Senac e da OIT/Cinterfor.'}</p>
            <p className='p'>{linguagemSelecionada == "ES" ? 'La interfaz es sencilla y accesible, ¡empieza a usarla ahora mismo!' : 'A interface é simples e acessível, comece a utilizá-la agora mesmo!'}</p>
          </div>

          <div className="botoes">
            <button onClick={handleActiveModalFilterDesk}>
              <a
                href="#main-content"
              >
                {linguagemSelecionada == "ES" ? 'Comience su búsqueda' : 'Inicie Sua Pesquisa'}
                <Image
                  priority
                  src={ArrowDown}
                  alt="Arrow Down Icon"
                  className='headerImage'
                />
              </a>
            </button>
            <button>
              <Link href="/sobre-a-plataforma">{linguagemSelecionada == "ES" ? 'Sepa Mas' : 'Saiba Mais'}</Link>
            </button>
          </div>
        </div>
        <div className='containerImg'>

          <Image
            priority
            src={linguagemSelecionada == "ES" ? HeaderImage : HeaderImagePT}
            alt="Imagem principal"
            className='headerImage'
          />
        </div>
      </div>

      <Main className="containerHome">
        <aside className={`filtro ${isModalVisible == true ? "active" : ''}`} id='aside'>
          {/* <hr className='hrCloseModal' onClick={(e) => setIsModalVisible(false)} /> */}
          <button onClick={(e) => setIsModalVisible(false)} className='closeButtonAside'>
            <Image
              priority
              src={XIconBig}
              alt="Imagem principal"
              className='x'
            />
          </button>
          <div className='SelectedFilters'>
            <header>
              <h3>Filtros</h3>

              <button onClick={handleRemoveFilters} className={`removeFilterButton ${filteredValues.length != 0 ? "active" : ''}`}>
                <span>{linguagemSelecionada == "ES" ? 'Eliminar todo ' : 'Remover Todos'}</span>
                <Image
                  priority
                  src={XIcon}
                  alt="Imagem principal"
                  className='x'
                />
              </button>
            </header>
            <div className='filters'>

              {
                filteredValues &&
                filteredValues.map((filter, index) => (
                  <SelectedFilters
                    key={index}
                    name={filter}
                    onFilterSelect={event => handleAdvancedFilter(event, { keyValue: selectedFilterTypes[event], filterValue: event })}
                    onClick={e => handleReturnName(e)}
                  />
                ))
              }

            </div>
          </div>

          <div className='startSearch'>
            <h2>{linguagemSelecionada == "ES" ? 'Comience su búsqueda' : 'Inicie sua pesquisa'}</h2>
          </div>

          <div className='classificacao'>
            <HeaderFilter title={linguagemSelecionada == "ES" ? 'Clasificación' : 'Classificação'} icon={Tag} />

            <div className='filters'>
              <Filters name="Método" tipo="classificacao" viewName={linguagemSelecionada == "ES" ? 'Método' : 'Método'} isActive={filterSettings.category} reset={resetFilter} onFilterSelect={event => handleAdvancedFilter(event, { keyValue: 'category', filterValue: event })} />
              <Filters name="Prática" tipo="classificacao" viewName={linguagemSelecionada == "ES" ? 'Práctica' : 'Prática'} isActive={filterSettings.category} reset={resetFilter} onFilterSelect={event => handleAdvancedFilter(event, { keyValue: 'category', filterValue: event })} />
              <Filters name="Recurso" tipo="classificacao" viewName={linguagemSelecionada == "ES" ? 'Recurso' : 'Recurso'} isActive={filterSettings.category} reset={resetFilter} onFilterSelect={event => handleAdvancedFilter(event, { keyValue: 'category', filterValue: event })} />
              <Filters name="Estratégia" tipo="classificacao" viewName={linguagemSelecionada == "ES" ? 'Estrategia' : 'Estratégia'} isActive={filterSettings.category} reset={resetFilter} onFilterSelect={event => handleAdvancedFilter(event, { keyValue: 'category', filterValue: event })} />
            </div>
          </div>

          <div className='finalidade'>
            <HeaderFilter title={linguagemSelecionada == 'ES' ? 'Propósito Pedagógico' : 'Finalidade Pedagógica'} icon={TargetArrow} />

            <div className='filters'>
              <Filters name="Análise de problemas e contexto" tipo="finalidade" isActive={filterSettings.finalidadesPedagogicas} viewName={linguagemSelecionada == "ES" ? 'Análisis de problemas y contexto' : 'Análise de problemas e contexto'} onFilterSelect={event => handleAdvancedFilter(event, { keyValue: 'finalidadesPedagogicas', filterValue: event })} />
              <Filters name="Apresentação ideias e resultados" tipo="finalidade" isActive={filterSettings.finalidadesPedagogicas} viewName={linguagemSelecionada == "ES" ? 'Presentación ideas y resultados' : 'Apresentação ideias e resultados'} onFilterSelect={event => handleAdvancedFilter(event, { keyValue: 'finalidadesPedagogicas', filterValue: event })} />
              <Filters name="Avaliação de ideias e resultados" tipo="finalidade" isActive={filterSettings.finalidadesPedagogicas} viewName={linguagemSelecionada == "ES" ? 'Evaluación de ideas y resultados' : 'Avaliação de ideias e resultados'} onFilterSelect={event => handleAdvancedFilter(event, { keyValue: 'finalidadesPedagogicas', filterValue: event })} />
              <Filters name="Diagnóstico de conhecimento e percepções" tipo="finalidade" isActive={filterSettings.finalidadesPedagogicas} viewName={linguagemSelecionada == "ES" ? 'Diagnóstico de conocimientos y percepciones' : 'Diagnóstico de conhecimento e percepções'} onFilterSelect={event => handleAdvancedFilter(event, { keyValue: 'finalidadesPedagogicas', filterValue: event })} />
              <Filters name="Formação de equipes de trabalho" tipo="finalidade" isActive={filterSettings.finalidadesPedagogicas} viewName={linguagemSelecionada == "ES" ? 'Formación de equipos de trabajo' : 'Formação de equipes de trabalho'} onFilterSelect={event => handleAdvancedFilter(event, { keyValue: 'finalidadesPedagogicas', filterValue: event })} />
              <Filters name="Geração e seleção de ideias" tipo="finalidade" isActive={filterSettings.finalidadesPedagogicas} viewName={linguagemSelecionada == "ES" ? 'Generación y selección de ideas' : 'Geração e seleção de ideias'} onFilterSelect={event => handleAdvancedFilter(event, { keyValue: 'finalidadesPedagogicas', filterValue: event })} />
              <Filters name="Planejamento e monitoramento" tipo="finalidade" isActive={filterSettings.finalidadesPedagogicas} viewName={linguagemSelecionada == "ES" ? 'Planificación y seguimiento' : 'Planejamento e monitoramento'} onFilterSelect={event => handleAdvancedFilter(event, { keyValue: 'finalidadesPedagogicas', filterValue: event })} />
              <Filters name="Reflexão sobre a aprendizagem" tipo="finalidade" isActive={filterSettings.finalidadesPedagogicas} viewName={linguagemSelecionada == "ES" ? 'Reflexión sobre el aprendizaje' : 'Reflexão sobre a aprendizagem'} onFilterSelect={event => handleAdvancedFilter(event, { keyValue: 'finalidadesPedagogicas', filterValue: event })} />
            </div>
          </div>

          <div className='modalidades'>
            <HeaderFilter title="Formato" icon={Pin} />

            <div className='inputs'>
              <Checkbox name="Presencial" viewName={linguagemSelecionada == "ES" ? 'En persona' : 'Presencial'} id="1" onFilterSelect={event => handleAdvancedFilter(event, { keyValue: 'formato', filterValue: event })} />
              <Checkbox name="On-line" viewName={linguagemSelecionada == "ES" ? 'On-line' : 'On-line'} id="2" onFilterSelect={event => handleAdvancedFilter(event, { keyValue: 'formato', filterValue: event })} />
              {/* <Checkbox name="Híbrido" viewName={linguagemSelecionada == "ES" ? 'Híbrido' : 'Híbrido'} id="3" onFilterSelect={event => handleAdvancedFilter(event, { keyValue: 'formato', filterValue: event })} /> */}
            </div>

          </div>

          <div className='formato'>
            <HeaderFilter title={linguagemSelecionada == "ES" ? 'Tipo de actividad' : 'Tipo de Atividade'} icon={Circles} />

            <div className='inputs'>
              <Checkbox name="Síncrona" viewName={linguagemSelecionada == "ES" ? 'Sincrónico' : 'Síncrona'} id="4" onFilterSelect={event => handleAdvancedFilter(event, { keyValue: 'tipodeatividade', filterValue: event })} />
              <Checkbox name="Assíncrona" viewName={linguagemSelecionada == "ES" ? 'Asincrónico' : 'Assíncrona'} id="5" onFilterSelect={event => handleAdvancedFilter(event, { keyValue: 'tipodeatividade', filterValue: event })} />
            </div>

          </div>

          <div className='participantes'>
            <HeaderFilter title={linguagemSelecionada == "ES" ? 'Participantes' : 'Participantes'} icon={UsersGroup} info="true" toolTitle={linguagemSelecionada == "ES" ? 'Ingrese el número mínimo y máximo de participantes en los campos indicados' : 'Insira o número de participantes mínimo e máximo nos campos indicados'} />
            {/* <DualInputRangeTime id="1" min="1" max={participantesMax ? Number(participantesMax) : "300"} valor={key} reset={resetFilter} onMinChange={handleMinChangeParcipantes} onMaxChange={handleMaxChangeParcipantes} /> */}
            <InputText id="1" valor={key} max={maxValue} min={minValue} reset={resetFilter} onMinChange={handleMinChangeParcipantes} onMaxChange={handleMaxChangeParcipantes} viewName="Participantes" />
          </div>

          <div className='tempo'>
            <HeaderFilter title={linguagemSelecionada == "ES" ? 'Tiempo' : 'Tempo'} icon={Clock} info="true" toolTitle={linguagemSelecionada == "ES" ? 'Ingresa los valores de tiempo mínimo y máximo en los campos indicados, considera siempre el tiempo en minutos.' : 'Insira os valores de tempo mínimo e máximo nos campos indicados, considere sempre o tempo em minutos.'} />
            {/* <DualInputRangeTime id="2" min="5" max={tempoMax ? Number(tempoMax) : "500"} valor={key} reset={resetFilter} onMinChange={handleMinChangeTempo} onMaxChange={handleMaxChangeTempo} /> */}
            <InputText id="2" valor={key} max={maxValueTempo} min={minValueTempo} reset={resetFilter} onMinChange={handleMinChangeTempo} onMaxChange={handleMaxChangeTempo} viewName="Tempo" />
          </div>

          <div className='facilitadores'>
            <HeaderFilter title="Facilitadores" icon={Git} />
            <SingleInputRangeTime id="3" min="1" valor={key} max="12" reset={resetFilter} onMinChange={handleMinChangeFacilitadores} viewName="Facilitadores" /* onMaxChange={handleMaxChangeFacilitadores} */ />
          </div>

          <div className='palavras-chave'>
            <div className='content'>
              <h3>{linguagemSelecionada == "ES" ? 'Palabras Clave' : 'Palavras-Chave'}</h3>
              <div className='filters'>
                {
                  tagsQuery &&
                  tagsQuery.map((tag, index) => (
                    index <= limitKeywords ?
                      <KeyWords key={tag.id} name={tag.name} isActive={filterSettings.keywords} reset={resetFilter} onFilterSelect={event => handleAdvancedFilter(event, { keyValue: 'keywords', filterValue: event })} />
                      : false
                  ))
                }

              </div>
            </div>

            <button className='carregarmais' /* onClick={handleMoreKeywords} */ onClick={loadMoreTags}>

              <span>{linguagemSelecionada === "ES" ? (carregarMais ? 'Carga más' : 'Carga menos') : (carregarMais ? 'Carregar Mais' : 'Carregar Menos')}</span>
              <Image
                src={carregarMais ? Plus : Minus}
                alt={carregarMais ? 'Plus Icon' : 'Minus Icon'}
                className='plus'
              />
            </button>
            {/* {!hasNextPage && (
              <button onClick={hideTags}>Esconder tags</button>
            )} */}
          </div>

          {/* <button onClick={(e) => setIsModalVisible(false)} className='filtrarButton'>
            Filtrar
          </button> */}


        </aside>

        <div className="listaoContainer">
          <div className="search">
            <div className="leftSearch">
              <Image
                priority
                src={BarChart}
                alt="Bar Chart Icon"
                className=''
              />

              <button onClick={handleAlphabeticalOrder} className={alphabetical == true ? "isActive" : "disable"}>A-Z</button>
              <button onClick={handleNewOrder} className={isReversed == true ? "isActive" : "disable"}>Z-A</button>
              {/* <button>Popularidade</button> */}
              <button onClick={handleOrderPublication} className={publication == true ? "isActive" : "disable"}>
                <span>{linguagemSelecionada == "ES" ? 'Noticias ' : 'Novidades'}</span>
              </button>
              {/* <Image
                priority
                src={Info}
                alt="Info Icon"
                className='info'
                onMouseEnter={handleIconHover}
                onMouseOut={handleModalClose}

              />
              {isModalVisible && (
                <div className="modal" >
                  <p>Os conteúdos foram organizados e classificados a partir dos objetivos pedagógicos, criando um ambiente de pesquisa para auxiliar no planejamento de aulas mais criativas e colaborativas. O mecanismo de busca permite a localização de diferentes recursos, de acordo com seu objetivo.</p>
                </div>
              )} */}
            </div>
            <div className="inputArea" id='busca'>
              <Image
                src={SearchIcon}
                alt="Search Icon"
              />
              <input
                type="text"
                placeholder={linguagemSelecionada == "ES" ? 'Buscar' : 'Pesquisa'}
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>


          <div className="listao" >
            {
              filteredPosts == '' ?
                <p>{linguagemSelecionada == "ES" ? '¡Ningún resultado encontrado!' : 'Nenhum resultado encontrado!'}</p> :
                ""
            }

            {(loading || queryLoading) && (
              <>
                <div>
                  <Image
                    src="/svg/loading.svg"
                    width={100}
                    height={100}
                    alt="Carregando"
                  />
                </div>
              </>
            )}

            {/* {console.log('***', filteredPosts)} */}
            {
              filteredPosts &&
              filteredPosts?.map((post, idx) => (
                <div key={idx}>
                  {/* {console.log('***', post)} */}
                  <CardPosts

                    id={post.id}
                    title={post?.title}
                    description={post.excerpt}
                    image={HeaderImage}
                    acf={post.VulpeModuloDados}
                    uri={post.uri}
                    classificacao={post.categories.edges[0].node.name}
                    img={post?.featuredImage != null ? post?.featuredImage.node.mediaItemUrl.replace(`${process.env.NEXT_PUBLIC_WORDPRESS_URL}wp-content/uploads/`, '/wp-content/uploads/') : cardIcon}
                    language={linguagemSelecionada}
                  />
                </div>
              ))
            }
          </div>

        </div>
      </Main>

      <button className='buttonOpenModalFilter' onClick={handleActiveModalFilter}>
        Buscar
        <Image
          src={SearchIcon}
          alt="Search Icon"
        />
      </button>

      <button className={`buttonModalFilter ${isModalVisible == true ? "active" : ''}`} onClick={(e) => setIsModalVisible(false)}>
        Aplicar
        <Image
          src={SearchIcon}
          alt="Search Icon"
        />
      </button>
      <Footer language={linguagemSelecionada} />
    </>
  );
}


const GET_POSTS = gql`
  query getPosts($language: LanguageCodeFilterEnum!, $tagsCursor: String) {
    posts(
      first: 1000
      where: { orderby: { field: TITLE, order: ASC }, language: $language }
    ) {
      nodes {
        title
        uri
        VulpeModuloDados {
          facilitacao
          fieldGroupName
          formato
          tipodeatividade
          participantesMaximo
          participantesMinimo
          tempoMinimo
          tempoMaximo
          finalidadesPedagogicas
        }
        featuredImage {
          node {
            mediaItemUrl
            mediaDetails {
              height
              width
            }
            altText
            sourceUrl
          }
        }
        tags(first: 165, after: $tagsCursor) {
          nodes {
            name
            slug
          }
        }
        seo {
          metaDesc
        }
        excerpt
        id
        categories {
          edges {
            node {
              id
              name
            }
          }
        }
        language {
          name
          code
          locale
        }
        modified
        date
      }
    }
    tags(first: 100, after: $tagsCursor, where: { language: $language }) {
      edges {
        node {
          id
          name
          slug
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export async function getServerSideProps(context) {
  const language = context.query.language || 'PT';

  const { data } = await client.query({
    query: GET_POSTS,
    variables: { language },
  });

  return {
    props: {
      initialPosts: data.posts.nodes,
      initialTags: data.tags.edges.map(edge => edge.node),
    },
  };
}

// Component.variables = () => {
//   return {
//     headerLocation: MENUS.PRIMARY_LOCATION,
//     footerLocation: MENUS.FOOTER_LOCATION,
//   };
// };
