import classNames from 'classnames/bind';
import styles from './Checkbox.module.scss';
let cx = classNames.bind(styles);

export default function Checkbox({name, onFilterSelect, id, viewName}) {

  const handleFilterClick = (filterName) => {
    onFilterSelect(filterName);
  };
  return (
    <div className={cx('checkboxInput')}>
      <input type='checkbox' onClick={() => handleFilterClick(name)} aria-label={viewName}/>
      <span className={cx('checkbox-custom')}></span>
      <label>{viewName}</label>
    </div>
  );
}
