import { gql } from '@apollo/client';
import * as MENUS from '../constants/menus';
import { BlogInfoFragment } from '../fragments/GeneralSettings';
import {
  Header,
  Footer,
  Main,
  Container,
  ContentWrapper,
  EntryHeader,
  NavigationMenu,
  FeaturedImage,
  SEO,
} from '../components';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';


export default function Component(props) {
  const router = useRouter();

  const [linguagemSelecionada, setLinguagemSelecionada] = useState(props?.data.page.language.code);
  const [firstAcess, setFirstAcess] = useState(true);

  

  // Loading state for previews
  if (props.loading) {
    return <>Loading...</>;
  }



  const { title: siteTitle, description: siteDescription } =
    props?.data?.generalSettings;
  const primaryMenu = props?.data?.headerMenuItems?.nodes ?? [];
  const footerMenu = props?.data?.footerMenuItems?.nodes ?? [];
  const { title, content, featuredImage } = props?.data?.page ?? { title: '' };

  const handleChangeLaguage = (e) => {
    setLinguagemSelecionada(e)
    setFirstAcess(false);
  }

  useEffect(() => {
    console.log("entrou no useEffect", linguagemSelecionada);
    console.log(firstAcess);
    if (firstAcess == false) {
      console.log("entrou");
      if (linguagemSelecionada == "ES") {
        router.push('/es/sobre-esta-plataforma/');
      }else if (linguagemSelecionada == "PT"){
        router.push('/sobre-a-plataforma/');
      }
    }
  }, [linguagemSelecionada])

  return (
    <>
      {/* <Head>
        <link rel="stylesheet" href="https://use.typekit.net/osb5xjt.css"></link>
      </Head> */}
      <SEO
        title={siteTitle}
        description={siteDescription}
        imageUrl={featuredImage?.node?.sourceUrl}
      />
      <Header
        title={siteTitle}
        description={siteDescription}
        menuItems={primaryMenu}
        tipoPagina="sobre"
        changeLaguageGeneral={handleChangeLaguage}
        lang={linguagemSelecionada}

      />
      <div className='page'>
        <Main>
          <>
            {/* <EntryHeader title={title} image={featuredImage?.node} /> */}
            <ContentWrapper content={content} />
          </>
        </Main>
        <div className="sendForm" id='contato'>
          <div className="container">
            <strong>{linguagemSelecionada == "ES" ? '¿Preguntas o sugerencias?' : 'Dúvidas ou sugestões?'}</strong>
            <h3>{linguagemSelecionada == "ES" ? '¡Contáctenos!' : 'Entre em contato conosco!'}</h3>
            <div className="formGroup">
              <div className="col2">
                <input type="text" name="" id="" placeholder={linguagemSelecionada == "ES" ? 'Nombre Apellido' : 'Nome Sobrenome'}/>
                <input type="email" name="" id="" placeholder={linguagemSelecionada == "ES" ? 'email@email.com' : 'email@email.com'}/>
              </div>
              <textarea name="" id="" cols="30" rows="10"></textarea>
              <div className="col2">
                <span></span>
                {/* <span>Ou envie mensagem para <a href="mailto:"></a>webmaster@oitcinterfor.org</span> */}
                <button type="submit">
                {linguagemSelecionada == "ES" ? 'Enviar mensaje' : 'Enviar Mensagem'}
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                    <path d="M11.2929 4.79289C11.6834 4.40237 12.3166 4.40237 12.7071 4.79289L19.7071 11.7929C20.0976 12.1834 20.0976 12.8166 19.7071 13.2071L12.7071 20.2071C12.3166 20.5976 11.6834 20.5976 11.2929 20.2071C10.9024 19.8166 10.9024 19.1834 11.2929 18.7929L16.5858 13.5H5C4.44772 13.5 4 13.0523 4 12.5C4 11.9477 4.44772 11.5 5 11.5H16.5858L11.2929 6.20711C10.9024 5.81658 10.9024 5.18342 11.2929 4.79289Z" fill="white" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer title={siteTitle} menuItems={footerMenu} language={linguagemSelecionada}/>
      </div>
    </>
  );
}

Component.variables = ({ databaseId }, ctx) => {
  return {
    databaseId,
    headerLocation: MENUS.PRIMARY_LOCATION,
    footerLocation: MENUS.FOOTER_LOCATION,
    asPreview: ctx?.asPreview,
  };
};

Component.query = gql`
  ${BlogInfoFragment}
  ${NavigationMenu.fragments.entry}
  ${FeaturedImage.fragments.entry}
  query GetPageData(
    $databaseId: ID!
    $headerLocation: MenuLocationEnum
    $footerLocation: MenuLocationEnum
    $asPreview: Boolean = false
  ) {
    page(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
      title
      content
      ...FeaturedImageFragment
      language {
        code
        name
        slug
      }
    }
    generalSettings {
      ...BlogInfoFragment
    }
    footerMenuItems: menuItems(where: { location: $footerLocation }) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    headerMenuItems: menuItems(where: { location: $headerLocation }) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
  }
`;
