import classNames from 'classnames/bind';
import styles from './AcfFields.module.scss';
let cx = classNames.bind(styles);
import Image from 'next/image';
import { Tooltip } from 'react-tooltip'

export default function AcfFields(props) {
  return (
    <div className={cx('field')} 
      data-tooltip-id="my-tooltip"
      data-tooltip-content={props.title}
      data-tooltip-place="top"
    >
      <Image
        src={props.image}
        alt=""
        className={cx('imagePrincipal')}
      />
      <Tooltip id="my-tooltip" />
    </div>
  );
}
