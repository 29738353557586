import className from 'classnames/bind';
import styles from './ContentWrapper.module.scss';
import Image from 'next/image';
// import { parse as parseHTMLString } from 'node-html-parser';

import parse from 'html-react-parser';

let cx = className.bind(styles);

const options = {
  replace: domNode => {
    if (domNode.attribs && domNode.name === 'img') {
      return (
        <Image
          src={domNode.attribs.src.replace(`${process.env.NEXT_PUBLIC_WORDPRESS_URL}wp-content/uploads/`, '/wp-content/uploads/')}
          alt={domNode.attribs.alt || ''}
          width={500}
          height={300}
          layout="responsive"
        />
      );
    }
  }
};

export default function ContentWrapper({ content, children, className }) {
  return (
    <article className={cx(['component', className])}>
      {/* <div dangerouslySetInnerHTML={{ __html: content ?? '' }} /> */}
      <div>{parse(content, options)}</div>
      {children}
    </article>
  );
}
