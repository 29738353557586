import className from 'classnames/bind';
import { Heading, PostInfo, Container, FeaturedImage } from '../../components';
import styles from './EntryHeader.module.scss';
import Image from 'next/image';

import headerImageES from '../../public/images/header-default-pratica-es.png'
import headerImage from '../../public/images/imagem-fixa-capa-pt-br.png'


let cx = className.bind(styles);

export default function EntryHeader({ title = '', image, date, author, className, language, id }) {
  const hasText = title || date || author;

  return (
    <>
      <div className={cx(['component', className])} id={id}>
        <Image
          className={cx('headerSingle')}
          priority
          width={1920}
          height={410}
          src={language == "ES" ? headerImageES : headerImage}
          alt="Search Icon"
        />
      </div>
    </>
    // <div className={cx(['component', className])}>
    //   {image && (
    //     <FeaturedImage
    //       image={image}
    //       className={cx('image')}
    //       priority
    //     />
    //   )}

    //   {hasText && (
    //     <div className={cx('text', { 'has-image': image })}>

    //       <Container>
    //         <div>Plataforma Ísis</div>

    //         {!!title && <Heading className={cx('title')}>{title}</Heading>}
    //         <PostInfo
    //           className={cx('byline')}
    //           author={author}
    //           date={date}
    //         />
    //       </Container>
    //     </div>
    //   )}

    // </div>
  );
}
