import React, { useState, useEffect } from 'react';

export default function DualInputRangeTime(props) {
  const [fromValue, setFromValue] = useState(Number(props.min));
  const [toValue, setToValue] = useState(Number(props?.max));

  const [initialFromValue, setInitialFromValue] = useState(Number(props?.min));
  const [initialToValue, setInitialToValue] = useState(Number(props?.max));

  const [status, setStatus] = useState(props.reset);

  // setTimeout(function() {
  //   setInitialToValue(props?.max);
  // }, [1000]);

  const resetToInitialValues = () => {
    setFromValue(initialFromValue);
    setToValue(initialToValue)
  };

  useEffect (() => {
    if (props.id == 1) {
      if (!props.valor.includes("participantes")) {
        setFromValue(initialFromValue);
        setToValue(initialToValue);
      }
    } else {
      if (!props.valor.includes("tempo")) {
        setFromValue(initialFromValue);
        setToValue(initialToValue);
      }
    }
  }, [props.valor, props.max])

  useEffect (() => {
    if (props.reset == true) {
      resetToInitialValues()
      setStatus(false)
    }
  }, [props.reset])


  useEffect(() => {
    handleSliders();
  }, [fromValue, toValue, props.max]);

  const handleSliders = () => {
    const [from, to] = getSliderValues();
    if (from > to) {
      setFromValue(to - 1);
      setToValue(from + 1);
    }
    setLabels(from, to);
    fillSlider(from, to);
  };

  const getSliderValues = () => {
    const from = parseInt(fromValue);
    const to = parseInt(toValue);
    return [from, to];
  };

  useEffect(() => {
    setLabels(props.min, props.max)
    fillSlider(props.min, props.max)
    setToValue(props.max)
  }, [props.max])


  const setLabels = (from, to) => {
    const fromLabelElement = document.querySelector(`.range-slider__value--min-${props.id}`);
    const toLabelElement = document.querySelector(`.range-slider__value--max-${props.id}`);
    fromLabelElement.style.left = (((from - 1) / props.max) * 100) + '%';
    toLabelElement.style.left = (((to - 1) / props.max) * 100) + '%';

    if (props.id == 1) {
      fromLabelElement.innerText = from;
      toLabelElement.innerText = to;
      
    } else {
      fromLabelElement.innerText = props.id == 2 ? `${from}min` : from;
      toLabelElement.innerText = `${to}min`;
    }
  };

  const fillSlider = (from, to) => {
    const fromSliderElement = document.querySelector(`.range-slider__slider--from-${props.id}`);
    const toSliderElement = document.querySelector(`.range-slider__slider--to-${props.id}`);
    const controlSliderElement = toSliderElement;

    const rangeDistance = props.max - props.min;
    const fromPosition = fromSliderElement.value - props.min;
    const toPosition = toSliderElement.value - props.min;
    controlSliderElement.style.background = `linear-gradient(
      to right,
      transparent 0%,
      transparent ${(fromPosition / rangeDistance) * 100}%,
      #22AA96 ${(fromPosition / rangeDistance) * 100}%,
      #22AA96 ${(toPosition / rangeDistance) * 100}%, 
      transparent ${(toPosition / rangeDistance) * 100}%, 
      transparent 100%)`;
  };

  return (
    <>
    <div className={`range-slider range-slider--dual-${props.id}`}>
      <div className={`range-slider__indicators-${props.id}`}>
        <div className={`range-slider__value range-slider__value--min-${props.id}`}></div>
        <div className={`range-slider__value range-slider__value--max-${props.id}`}></div>
      </div>
      <input
        className={`range-slider__slider range-slider__slider--from-${props.id}`}
        type="range"
        value={fromValue}
        min={Number(props.min)}
        max={Number(props.max)}
        onChange={(e) => {
          setFromValue(e.target.value);
          props.onMinChange(e.target.value);
        }}
      />
      <input
        className={`range-slider__slider range-slider__slider--to-${props.id}`}
        type="range"
        value={toValue}
        min={Number(props.min)}
        max={Number(props.max)}
        onChange={(e) => {
          setToValue(e.target.value);
          props.onMaxChange(e.target.value);
        }}
      />
      <div className={`range-slider__scale-${props.id}`}>
        {/* Scale elements */}
      </div>
    </div>
    <div className={`minMax-${props.id}`}>
      <span>Min.</span>
      <span>Máx.</span>
    </div>
    </>
  );
}
