import classNames from 'classnames/bind';
import styles from './HeaderFilter.module.scss';
let cx = classNames.bind(styles);
import Image from 'next/image';
import TooltipComponent from '../TooltipComponent/TooltipComponent';


export default function HeaderFilter(props) {
  
  return (
    <header className={cx('headerFilter')}>
      <Image
        priority
        src={props.icon}
        alt="Ícone Filtro"
      />
      <h3>{props.title}</h3>

      {
        props.info == 'true' &&

        <TooltipComponent 
          title={props.toolTitle}
        />
      }
    </header>
  );

}
