import classNames from 'classnames/bind';
import styles from './KeyWords.module.scss';
import { useEffect, useState } from 'react';
let cx = classNames.bind(styles);

/* Essa parte isActive eu não gostei dessa forma, estava fazendo só para testar mesmo. Depois passo olhando essas coisas. */
export default function KeyWords({name, onFilterSelect, isActive, reset}) {
  
  const [estado, setEstado] = useState();


  const handleFilterClick = (filterName) => {
    onFilterSelect(filterName);

    if (isActive.indexOf(filterName) !== -1) {
      setEstado('isActive')
    }else {
      setEstado('disable')
    }
  };

  useEffect (() => {
    if (isActive.indexOf(name) !== -1) {
      setEstado('isActive')
    }else {
      setEstado('disable')
    }
  }, [isActive])


  useEffect (() => {
    if (reset == true) {
      setEstado('disable');
    }
  }, [reset])
  
  return (
    <>
      <button className={cx(`${estado != undefined ? estado : 'disable'}`)} onClick={() => handleFilterClick(name)}>
        <span>{name}</span>
      </button>
    </>
  );
}
