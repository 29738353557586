import classNames from 'classnames/bind';
import styles from './Filters.module.scss';
import { useEffect, useState } from 'react';
let cx = classNames.bind(styles);

/* Essa parte isActive eu não gostei dessa forma, estava fazendo só para testar mesmo. Depois passo olhando essas coisas. */
export default function Filters({ name, viewName, onFilterSelect, isActive, reset, tipo}) {
  const [estado, setEstado] = useState(isActive);
  const [resetVar, setResetVar] = useState(reset);

  // console.log("filter:", reset);
  const handleFilterClick = (filterName) => {
    onFilterSelect(filterName);

    if (isActive.indexOf(name) !== -1) {
      setEstado('isActive')
    }else {
      setEstado('disable')
    }

  };

  useEffect (() => {
    if (isActive.indexOf(name) !== -1) {
      setEstado('isActive')
    }else {
      setEstado('disable')
    }
  }, [isActive])

  useEffect (() => {
    if (reset == true) {
      setEstado('disable');
    }
  }, [reset])

  return (
    <>
      <button className={cx(estado != undefined ? estado : 'disable', `${tipo}`)} onClick={() => handleFilterClick(name)} name={name} >
        <span>{viewName}</span>
      </button>
    </>
  );
}
