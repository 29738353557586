import classNames from 'classnames/bind';
import Image from 'next/image';
import Head from 'next/head'
import styles from './Footer.module.scss';
import Link from 'next/link';

let cx = classNames.bind(styles);

import LogoSenac from '../../public/images/logo-senac.png';
import LogoCinterfor from '../../public/images/logo-cinterfor.png';

import LinkedInLogo from '../../public/svg/brand-linkedin.svg';
import YoutubeLogo from '../../public/svg/brand-youtube.svg';
import InstagramLogo from '../../public/svg/brand-instagram.svg';
import FacebookLogo from '../../public/svg/brand-facebook.svg';
import TwitterLogo from '../../public/svg/brand-twitter.svg';

export default function Footer(props) {
  const year = new Date().getFullYear();

  return (
    <>
      <footer className={cx('component')}>
        <div className={cx('containerFooter')}>
          <div className={cx('first')}>

            <div className={cx('containerLogo')}> 
              <h4>{props.language == "ES" ? "Desarrollado por:": "Desenvolvido por:"}</h4>

              <div className={cx('logos')}>
                <Image
                  priority
                  src='/svg/logo-footer-senac.svg'
                  alt="Senac logo"
                  className={cx('senac')}
                  width={70}
                  height={62}
                />

                <Image
                  priority
                  src="/svg/logo-footer-cinterfor.svg"
                  alt="Cinterfor logo"
                  className={cx('cinterfor')}
                  width={260}
                  height={78}
                />
              </div>
            </div>

            <div className={cx('containerSocial')}>
              <small>{props.language == "ES" ? "Síganos em redes sociales": "Siga-nos nas redes sociais:"}</small>
              <ul className={cx('social')}>
                <li>
                  <a href='https://www.linkedin.com/company/oit-cinterfor/' target='_blank'>
                    <Image
                      priority
                      src={LinkedInLogo}
                      alt="LinkedIn logo"
                    />
                  </a>
                </li>
                
                <li>
                  <a href='https://www.youtube.com/user/oitcinterfor' target='_blank'>
                    <Image
                      priority
                      src={YoutubeLogo}
                      alt="Youtube logo"
                    />
                  </a>
                </li>

                {/* <li>
                  <a>
                    <Image
                      priority
                      src={InstagramLogo}
                      alt="Youtube logo"
                    />
                  </a>
                </li> */}

                <li>
                  <a href='https://www.facebook.com/oitcinterfor' target='_blank'>
                    <Image
                      priority
                      src={FacebookLogo}
                      alt="Facebook logo"
                    />
                  </a>
                </li>

                <li>
                  <a href='https://twitter.com/OITCinterfor' target='_blank'>
                    <Image
                      priority
                      src={TwitterLogo}
                      alt="Facebook logo"
                    />
                  </a>
                </li>
              </ul>
            </div>


          </div>

          <div className={cx('copyAndNavigation')}>
            <span>Copyright {year}.</span>
            <nav className={cx('navFooter')}>
              <ul className={cx('ulFooter')}>
                <li>
                  <a href='#'>{props.language == "ES" ? "Política de privacidad": "Política de Privacidade"}</a>
                </li>

                <li>
                  <a href='#'>{props.language == "ES" ? "Términos y Condiciones": "Termos & Condições"}</a>
                </li>

                <li>
                  <a href='#'>{props.language == "ES" ? "Políticas de Cookies": "Política de Cookies"}</a>
                </li>

                <li>
                  <Link href="/sobre-a-plataforma/#contato" >
                    <a href='#'>{props.language == "ES" ? "Contacto": "Contato"}</a>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </footer>
    </>
  );
}
