import classNames from 'classnames/bind';
import styles from './TooltipComponent.module.scss';
let cx = classNames.bind(styles);
import Image from 'next/image';
import { Tooltip } from 'react-tooltip'

import Info from '../../public/svg/info.svg'

export default function TooltipComponent(props) {
  return (
    <div className={cx('field')} 
      data-tooltip-id="tooltip-info"
      data-tooltip-content={props.title}
      data-tooltip-place="top-start"
    >
      <Image
        src={Info}
        alt=""
        className={cx('imagePrincipal')}
      />
      <Tooltip 
        id="tooltip-info" 
        style={{ backgroundColor: "#22355F", color: "#FFF", maxWidth: "240px", zIndex: "9999" }}
      />
    </div>
  );
}
