import React, { useState, useEffect } from 'react';
// import  './InputText.module.scss';

export default function InputText(props) {
  const [fromValue, setFromValue] = useState(Number(props.min));
  const [toValue, setToValue] = useState(Number(props?.max));

  const [initialFromValue, setInitialFromValue] = useState(Number(props?.min));
  const [initialToValue, setInitialToValue] = useState(Number(props?.max));

  const [status, setStatus] = useState(props.reset);


  useEffect(() => {
    if (!status) {
      resetToInitialValues()
    }
  }, [status, props.reset])

  useEffect(() => {
    if (props.max == "" && props.min == "") {
      resetToInitialValues()
    }
  }, [props.max, props.min])

  const resetToInitialValues = () => {
    setFromValue('')
    setToValue('')
  };


  return (
    <>
      <div className={`input-text input--dual-${props.id}`}>
        <input
          className={`input-slider input--from-${props.id}`}
          type="number"
          value={fromValue}
          min="0"
          onChange={(e) => {
            setFromValue(e.target.value);
            props.onMinChange(e.target.value);
          }}
          aria-label={`${props.viewName} mínimo`}
        />
        
        <input
          className={`input-slider input--to-${props.id}`}
          type="number"
          value={toValue}
          min="0"
          onChange={(e) => {
            setToValue(e.target.value);
            props.onMaxChange(e.target.value);
          }}
          aria-label={`${props.viewName} máximo`}
        />

      </div>
      <div className={`minMax-${props.id}`}>
        <span>Min.</span>
        <span>Máx.</span>
      </div>
    </>
  );
}
