import { useQuery, gql } from '@apollo/client';
import { useRouter } from 'next/router';
import { database } from '../firebase';
import Head from 'next/head'
import * as MENUS from '../constants/menus';
import { BlogInfoFragment } from '../fragments/GeneralSettings';

import SocialShare from '../components/SocialShare/SocialShare';
import {
  Header,
  Footer,
  Main,
  Container,
  EntryHeader,
  NavigationMenu,
  ContentWrapper,
  FeaturedImage,
  SEO,
} from '../components';
import Image from 'next/image';
import Link from 'next/link';
import { useState, useEffect } from 'react';
import { ref, update, onValue } from "firebase/database";

import Pin from '../public/svg/map-pin.svg';
import Circles from '../public/svg/circles.svg';
import Clock from '../public/svg/clock-hour-4.svg';
import Git from '../public/svg/git-branch.svg';
import UsersGroup from '../public/svg/users-group.svg';
import TargetArrow from '../public/svg/target-arrow.svg';

import LinkedInLogo from '../public/svg/brand-linkedin-compartilhamento.svg';
import YoutubeLogo from '../public/svg/brand-youtube-compartilhamento.svg';
import InstagramLogo from '../public/svg/brand-instagram-compartilhamento.svg';
import FacebookLogo from '../public/svg/brand-facebook-compartilhamento.svg';
import TwitterLogo from '../public/svg/brand-twitter-compartilhamento.svg';

import { CardPosts } from '../components/CardPosts';
import stripTags from "striptags";

const GET_POST = gql`
  query GetPost($categoryName: String!){
    posts(first: 10, where: {categoryName: $categoryName}) {
      edges {
        node {
          title
          uri
          id
          excerpt
          featuredImage {
            node {
              mediaItemUrl
              sourceUrl
            }
          }
          VulpeModuloDados {
            participantesMinimo
            participantesMaximo
            tempo
            facilitacao
            tipodeatividade
            formato
            finalidadesPedagogicas
          }
          categories(first: 10) {
            edges {
              node {
                name
              }
            }
          }
        }
      }
    }
  }
`;

const GET_TRANSLATE = gql`
query post {
  post (id: "cG9zdDoyOTg=") {
    translation(language: ES) {
      VulpeModuloDados {
        facilitacao
        fieldGroupName
        finalidadesPedagogicas
        formato
        participantesMaximo
        participantesMinimo
        tempo
        tipodeatividade
      }
      id
      link
      slug
      uri
    }
  }
}
`

export default function Component(props) {
  const router = useRouter();

  const [count, setCount] = useState(0);
  const [gosteiClicado, setGosteiClicado] = useState(false);
  const [linguagemSelecionada, setLinguagemSelecionada] = useState("PT");
  const [postsRelacionadosFiltered, setpostsRelacionadosFiltered] = useState([]);
  const [shareButton, setShareButton] = useState(false);
  
  const [minimoTempo, setMinimoTempo] = useState();
  const [maximoTempo, setMaximoTempo] = useState();

  useEffect(() => {
    const { tempoMinimo, tempoMaximo } = props.data.post.VulpeModuloDados || {};
    setMinimoTempo(tempoMinimo ? `${tempoMinimo}min` : 'Sem limite');
    setMaximoTempo(tempoMaximo && tempoMaximo !== 500 ? `${tempoMaximo}min` : 'Sem limite');
  }, []);


  const urlDaPagina = `https://cinterfor.vulpecomunicacao.com.br${router.asPath}`;

  // Loading state for previews
  if (props.loading) {
    return <>Loading...</>;
  }

  // Tentativa de fazer funcionar a tradução 
  // const language = "ES"; 

  // const { data } = useQuery(GET_POST, {
  //   variables: {
  //     language: String(language),
  //   },
  // });


  const handleSearchKeyword = (event) => {
    const keyword = event
    router.push(`/?keyword=${keyword}`);
  };

  const { title: siteTitle, description: siteDescription } = props?.data?.generalSettings;
  const primaryMenu = props?.data?.headerMenuItems?.nodes ?? [];
  const footerMenu = props?.data?.footerMenuItems?.nodes ?? [];
  const { title, content, featuredImage, date, author, uri, link, id } = props.data.post;
  const postsRelacionados = props?.data?.posts?.edges;
  const category = props?.data?.post.categories?.nodes[0].name;


  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  // useEffect(() => {
  //   const categoryLowerCase = removeAccents(category.toLowerCase());

  //   // Filtrar por category excluuindo o post em si
  //   const filtered = postsRelacionados?.filter((post) =>
  //     post?.node.uri.includes(categoryLowerCase) && post?.node.id !== id
  //   );

  //   setpostsRelacionadosFiltered(filtered)

  // }, [postsRelacionados])

  useEffect(() => {
    // Verifique o localStorage quando o componente for montado
    const gosteiClicadoLocalStorage = localStorage.getItem(`gosteiClicado-${id}`) === 'true';
    setGosteiClicado(gosteiClicadoLocalStorage);
  }, []);


  const handleLike = () => {

    if (localStorage.getItem(`gosteiClicado-${id}`) !== 'true') {
      setCount((event) => event + 1);
      const postData = {
        title: title,
        count: count + 1
      };
      const updates = {};
      updates[`/posts/${id}`] = postData;

      setGosteiClicado(true);
      localStorage.setItem(`gosteiClicado-${id}`, 'true');

      return update(ref(database), updates);

    } else {
      setCount((event) => event - 1);
      const postData = {
        title: title,
        count: count - 1
      };
      const updates = {};
      updates[`/posts/${id}`] = postData;

      setGosteiClicado(false);
      localStorage.setItem(`gosteiClicado-${id}`, 'false');

      return update(ref(database), updates);
    }

  };

  useEffect(() => {
    const countRef = ref(database, `posts/${id}`);
    onValue(countRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setCount(data?.count);
      }
    });
  }, [count]);

  const handleShare = () => {
    // if (navigator.share) {
    //   navigator.share({
    //     title: siteTitle,
    //     text: siteDescription,
    //     url: urlDaPagina,
    //     // files: [featuredImage?.node?.sourceUrl],
    //   })
    //     .then(() => console.log('Conteúdo compartilhado com sucesso'))
    //     .catch((error) => console.error('Erro ao compartilhar conteúdo', error));
    // } else {
    //   console.error('API de compartilhamento não suportada no seu navegador');
    // }

    if (shareButton == false) {
      setShareButton(true);
    } else {
      setShareButton(false);
    }

  };

  const handleChangeLaguage = (e) => {
    setLinguagemSelecionada(e)
  }

  const handleFacebook = (e) => {
    var url = "https://www.facebook.com/sharer/sharer.php?u=";
    openNewTab(url);
  }

  const handleLinkedin = (e) => {
    var url = "https://www.linkedin.com/sharing/share-offsite/?url=";
    openNewTab(url);
  }

  const handletwitter = (e) => {
    var url = "https://twitter.com/intent/tweet?text=";
    openNewTab(url);
  }

  function openNewTab(url) {
    // URL da sua página que você deseja compartilhar
    var paginaURL = encodeURIComponent(window.location.href);

    // Combine as duas URLs
    var urlContatenada = url + paginaURL;

    // Abra uma nova janela ou guia para o compartilhamento no LinkedIn
    window.open(urlContatenada, "_blank");
  }

  // Request wpgraphql
  const { loading, error, data: data2 } = useQuery(GET_POST, {
    variables: {
      databaseId: props.data.post.postID,
      categoryName: props?.data.post.categories.nodes[0].name, // Substitua pelo valor real
    },
  });

  

  // if (loading) return <p>Carregando...</p>;
  // if (error) return <p>Ocorreu um erro ao carregar os dados.</p>;

  // console.log('***', loading);
  // console.log('***', error);

  const post = data2?.posts;
  // Fim request wpgraphql


  // Vini
  // console.log('***', props?.data);
  // console.log('***', props?.data.post.categories.nodes[0].name);
  // console.log('****', post);


  return (
    <>
      <Head>
        {/* <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        <link href="https://fonts.googleapis.com/css2?family=Playfair+Display:wght@900&family=Roboto:wght@400;500;700&display=swap" rel="stylesheet" /> */}
        <link rel="stylesheet" href="https://use.typekit.net/osb5xjt.css"></link>
        <link rel="canonical" href={props.data.link}></link>
        {/* canonical */}
        {/* AMP */}
      </Head>

      <SEO
        title={`${props?.data.post.title} | ${siteTitle}`}
        description={stripTags(props?.data.post.excerpt)}
        imageUrl={featuredImage?.node?.sourceUrl}
        tags={props?.data.post.tags.nodes}
        // keyword={props?.data.post.seo.metaKeywords}
        site={siteTitle}
      />

      <Header
        title={siteTitle}
        titleMateria={title}
        description={siteDescription}
        menuItems={primaryMenu}
        tipoPagina="single"
        language={props.data.post.language.code}
        changeLaguageGeneral={handleChangeLaguage}
        idFicha={props.data.post.id}
      />
      <Main>
        <>
          <EntryHeader
            title={title}
            image={featuredImage?.node}
            date={date}
            author={author?.node?.name}
            language={props.data.post.language.code}
            id='title-single'
          />
          <Container >

            <div className="voltar" >
              <Link
                href="/"
              >
                <a>
                  <Image
                    src="/svg/arrow-left.svg"
                    width={18}
                    height={19}
                  />
                  {props.data.post.language.code == "PT" ? "Voltar" : "Volver"}
                </a>
              </Link>
            </div>

            <div className="entryHeader" >
              <div className="col">
                <h1>{title}</h1>
                <small>
                  <img src="/svg/tag.svg" alt="Ícone Tag" />
                  <span>{props.data.post.categories.nodes[0].name}</span>
                </small>
              </div>
              <div className="col firstContent">
                {/* <SocialShare
                  url={`http://localhost:3000${uri}`}
                  text={title}
                  className="socialShare"
                /> */}
                <div className={`like ${gosteiClicado === true ? 'active' : ''}`} onClick={handleLike}>
                  <div className="message">{gosteiClicado === true ? (props.data.post.language.code == "PT" ? "Obrigado!" : "Obrigado!") : (props.data.post.language.code == "PT" ? "Gostou? Dê um like!" : "¿Te gustó? ¡Dale like!")}</div>
                  <Image
                    priority
                    src={gosteiClicado === true ? '/svg/icon-like-active.svg' : '/svg/icon-like.svg'}
                    width={16}
                    height={17}
                    alt="Ícone de like"
                  />
                  <div className="countNum">{count}</div>
                </div>



                <div className={`share ${shareButton == true ? "active" : ""}`}>
                  <Image
                    priority
                    src='/svg/icon-share.svg'
                    width={44}
                    height={45}
                    alt="Ícone de compartilhamento"
                    onClick={handleShare}
                    className='iconShare'
                    color='#FFF'
                  />

                  <ul className='social'>
                    <li onClick={handleLinkedin}>
                      <a href='https://www.linkedin.com/company/oit-cinterfor/' target='_blank'>
                        <Image
                          priority
                          src={LinkedInLogo}
                          alt="LinkedIn logo"
                          width={24}
                          height={24}
                        />
                      </a>
                    </li>

                    {/* <li>
                      <a href='https://www.youtube.com/user/oitcinterfor' target='_blank'>
                        <Image
                          priority
                          src={YoutubeLogo}
                          alt="Youtube logo"
                          width={24}
                          height={24}
                        />
                      </a>
                    </li> */}

                    {/* <li>
                      <a>
                        <Image
                          priority
                          src={InstagramLogo}
                          alt="Youtube logo"
                          width={24}
                          height={24}
                        />
                      </a>
                    </li> */}

                    <li onClick={handleFacebook} name="facebook">
                      <a href='https://www.facebook.com/oitcinterfor' target='_blank'>
                        <Image
                          priority
                          src={FacebookLogo}
                          alt="Facebook logo"
                          width={24}
                          height={24}
                        />
                      </a>
                    </li>

                    <li onClick={handletwitter}>
                      <a href='https://twitter.com/OITCinterfor' target='_blank'>
                        <Image
                          priority
                          src={TwitterLogo}
                          alt="Facebook logo"
                          width={24}
                          height={24}
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='ContainerIcons'>
              <div className='iconesVulpe'>

                <div className='icon'>
                  <div className='containerImg'>
                    <Image
                      priority
                      src={TargetArrow}
                      width={28}
                      height={28}
                      alt="Ícone de compartilhamento"
                    />
                  </div>
                  <div className='words finalidade'>
                    <small>{props.data.post.language.code == "PT" ? 'Finalidade Pedagógica' : 'Finalidad Pedagógica'}</small>
                    {
                      props.data.post.VulpeModuloDados.finalidadesPedagogicas &&
                      props.data.post.VulpeModuloDados.finalidadesPedagogicas.map((finalidadesPedagogicas, id) => (
                        <strong key={id}>{finalidadesPedagogicas}</strong>
                      ))
                    }
                  </div>
                </div>

                <div className='icon'>
                  <div className='containerImg'>
                    <Image
                      priority
                      src={UsersGroup}
                      width={28}
                      height={28}
                      alt="Ícone de compartilhamento"
                    />
                  </div>
                  <div className='words'>
                    <small>Participantes</small>
                    <strong>{props.data.post.VulpeModuloDados.participantesMinimo == null ? "Sem Limite" : props.data.post.VulpeModuloDados.participantesMinimo} - {props.data.post.VulpeModuloDados.participantesMaximo == 300 ? "Sem limite" : props.data.post.VulpeModuloDados.participantesMaximo}</strong>
                  </div>
                </div>

                <div className='icon'>
                  <div className='containerImg'>
                    <Image
                      priority
                      src={Clock}
                      width={28}
                      height={28}
                      alt="Ícone de compartilhamento"
                    />
                  </div>
                  <div className='words'>
                    <small>{props.data.post.language.code == "PT" ? 'Tempo' : 'Tiempo'}</small>
                    <strong>{minimoTempo} - {maximoTempo}</strong>
                  </div>
                </div>

                <div className='icon'>
                  <div className='containerImg'>
                    <Image
                      priority
                      src={Circles}
                      width={28}
                      height={28}
                      alt="Ícone de compartilhamento"
                    />
                  </div>
                  <div className='words'>
                    <small>{props.data.post.language.code == "PT" ? 'Modalidade' : 'Modalidad'}</small>
                    {
                      props.data.post.VulpeModuloDados.tipodeatividade &&
                      props.data.post.VulpeModuloDados.tipodeatividade.map((tipodeatividade, id) => (
                        <strong key={id}>{tipodeatividade}</strong>
                      ))
                    }
                    {/* <strong>{props.data.post.VulpeModuloDados.tipodeatividade}</strong> */}
                  </div>
                </div>

                <div className='icon'>
                  <div className='containerImg'>
                    <Image
                      priority
                      src={Pin}
                      width={28}
                      height={28}
                      alt="Ícone de compartilhamento"
                    />
                  </div>
                  <div className='words'>
                    <small>Formato</small>
                    {
                      props.data.post.VulpeModuloDados.formato &&
                      props.data.post.VulpeModuloDados.formato.map((formato, id) => (
                        <strong key={id}>{formato}</strong>
                      ))
                    }
                  </div>
                </div>

                <div className='icon'>
                  <div className='containerImg'>
                    <Image
                      priority
                      src={Git}
                      width={28}
                      height={28}
                      alt="Ícone de compartilhamento"
                    />
                  </div>
                  <div className='words'>
                    <small>Facilitadores</small>
                    <strong>Mínimo de {props.data.post.VulpeModuloDados.facilitacao}</strong>
                  </div>
                </div>
              </div>
            </div>
          </Container>

          <ContentWrapper content={content} />

          <Container>
            <div id="palavrasChave" className='keyWords'>
              <h4>{props.data.post.language.code == "ES" ? "Palabras Clave" : "Palavras-Chave"}</h4>
              <ul>
                {props.data.post.tags.nodes.map((item, id) => (
                  <li key={id} onClick={() => handleSearchKeyword(item.name)} name={item.name}>{item.name}</li>
                ))}
              </ul>
              <hr />
            </div>
          </Container>

          {post?.edges.length >= 1 && (
            <Container>
              <div className='relacionadosContainer'>
                <h4>{props.data.post.language.code == "ES" ? 'Otras practicas pedagógicas' : 'Outras práticas pedagógicas para você!'}</h4>
                <div id='relacionados'>
                  {post?.edges.map((p, id) => (
                    <CardPosts
                      key={id}
                      id={p.node.id}
                      title={p.node.title}
                      description={p.node.excerpt}
                      // image={HeaderImage}
                      acf={p.node.VulpeModuloDados}
                      uri={p.node.uri}
                      classificacao={p?.node.categories?.edges[0]?.node.name}
                      img={p.node.featuredImage != null ? p.node.featuredImage.node.sourceUrl : cardIcon}
                      language={linguagemSelecionada}
                      single={true}
                    />
                  ))}
                </div>
              </div>
            </Container>
          )}

          {/* <Container>
            <div className='relacionadosContainer'>
              <h4>Outras práticas pedagógicas para você!</h4>
              <div id='relacionados'>
                {
                  postsRelacionadosFiltered &&
                  postsRelacionadosFiltered?.map((post) => (
                    <CardPosts
                      key={post.node.id}
                      id={post.node.id}
                      title={post.node.title}
                      description={post.node.excerpt}
                      // image={HeaderImage}
                      acf={post.node.VulpeModuloDados}
                      uri={post.node.uri}
                      // classificacao={post.categories.edges[0].node.name}
                      // img={post.featuredImage != null ? post.featuredImage.node.sourceUrl : cardIcon}
                      language={linguagemSelecionada}
                    />
                  ))
                }
              </div>
            </div>
          </Container> */}

        </>
      </Main>
      <Footer
        language={props.data.post.language.code}
      />
    </>
  );
}

Component.query = gql`
  ${BlogInfoFragment}
  ${NavigationMenu.fragments.entry}
  ${FeaturedImage.fragments.entry}
  query GetPost(
    $databaseId: ID!
    $headerLocation: MenuLocationEnum
    $footerLocation: MenuLocationEnum
    $asPreview: Boolean = false
  ){
    post(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
      title
      link
      uri
      id
      postId
      content
      VulpeModuloDados {
        participantesMinimo
        participantesMaximo
        tempoMaximo
        tempoMinimo
        facilitacao
        tipodeatividade
        formato
        finalidadesPedagogicas
      }
      tags(first: 20) {
        nodes {
          name
          uri
        }
      }
      language {
        name
        code
        locale
      }
      categories(first: 20) {
        nodes {
          name
        }
      }
      date
      author {
        node {
          name
        }
      }
      ...FeaturedImageFragment
    }
    generalSettings {
      ...BlogInfoFragment
    }
    headerMenuItems: menuItems(where: { location: $headerLocation }) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footerMenuItems: menuItems(where: { location: $footerLocation }) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
  }
`;

// Component.query = gql`
//           ${BlogInfoFragment}
//           ${NavigationMenu.fragments.entry}
//           ${FeaturedImage.fragments.entry}
//           query GetPost(
//           $databaseId: ID!,
//           $headerLocation: MenuLocationEnum,
//           $footerLocation: MenuLocationEnum,
//           $asPreview: Boolean = false,
//           $language: LanguageCodeEnum!
//           ) {
//             post(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview ) {
//             title
//             link
//             uri
//             id
//       content
//       VulpeModuloDados {
//         participantesMinimo
//         participantesMaximo
//         tempo
//         facilitacao
//         tipodeatividade
//         formato
//       }
//       tags(first: 50) {
//         nodes {
//           name
//           uri
//         }
//       }
//       translation(language: $language) {

//         id
//         link
//         slug
//         title
//         uri
//         content
//         VulpeModuloDados {
//           facilitacao
//           fieldGroupName
//           formato
//           participantesMaximo
//           participantesMinimo
//           tempo
//           tipodeatividade
//         }
//         tags(first: 50) {
//           nodes {
//             name
//             uri
//           }
//         }
//         categories {
//           nodes {
//             name
//           }
//         }
//         date
//         author {
//           node {
//             name
//           }
//         }
//       }
//       language {
//         name
//         code
//         locale
//       }
//       categories {
//         nodes {
//           name
//         }
//       }
//           date
//           author {
//             node {
//             name
//           }
//       }
//           ...FeaturedImageFragment
//     }
//           generalSettings {
//             ...BlogInfoFragment
//           }
//           headerMenuItems: menuItems(where: {location: $headerLocation }) {
//             nodes {
//             ...NavigationMenuItemFragment
//           }
//     }
//           footerMenuItems: menuItems(where: {location: $footerLocation }) {
//             nodes {
//             ...NavigationMenuItemFragment
//           }
//     }
//   }
//   `;

// const GET_POST = gql`
//    ${BlogInfoFragment}
//           ${NavigationMenu.fragments.entry}
//           ${FeaturedImage.fragments.entry}
//           query GetPost(
//           $databaseId: ID!
//           $headerLocation: MenuLocationEnum
//           $footerLocation: MenuLocationEnum
//           $asPreview: Boolean = false
//           $language: LanguageCodeEnum!
//           ) {
//             post(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
//             title
//             link
//             uri
//             id
//       content
//       VulpeModuloDados {
//         participantesMinimo
//         participantesMaximo
//         tempo
//         facilitacao
//         tipodeatividade
//         formato
//       }
//       tags(first: 50) {
//         nodes {
//           name
//           uri
//         }
//       }
//       translation(language: $language) {

//         id
//         link
//         slug
//         title
//         uri
//         content
//         VulpeModuloDados {
//           facilitacao
//           fieldGroupName
//           formato
//           participantesMaximo
//           participantesMinimo
//           tempo
//           tipodeatividade
//         }
//         tags(first: 50) {
//           nodes {
//             name
//             uri
//           }
//         }
//         categories {
//           nodes {
//             name
//           }
//         }
//         date
//         author {
//           node {
//             name
//           }
//         }
//       }
//       language {
//         name
//         code
//         locale
//       }
//       categories {
//         nodes {
//           name
//         }
//       }
//           date
//           author {
//             node {
//             name
//           }
//       }
//           ...FeaturedImageFragment
//     }
//           generalSettings {
//             ...BlogInfoFragment
//           }
//           headerMenuItems: menuItems(where: {location: $headerLocation }) {
//             nodes {
//             ...NavigationMenuItemFragment
//           }
//     }
//           footerMenuItems: menuItems(where: {location: $footerLocation }) {
//             nodes {
//             ...NavigationMenuItemFragment
//           }
//     }
//   }
// `;


/* 

Query que faz a tradução

query GetPost($id: ID = "cG9zdDozMzA=", $language: LanguageCodeEnum!) {
  post(id: $id) {
    title
    link
    uri
    id
    content
    VulpeModuloDados {
      participantesMinimo
      participantesMaximo
      tempo
      facilitacao
      tipodeatividade
      formato
    }
    tags(first: 50) {
      nodes {
        name
        uri
      }
    }
    language {
      name
      code
      locale
    }
    categories {
      nodes {
        name
      }
    }
    date
    author {
      node {
        name
      }
    }
    translation(language: $language) {
      VulpeModuloDados {
        facilitacao
        fieldGroupName
        formato
        participantesMaximo
        participantesMinimo
        tempo
        tipodeatividade
      }
      id
      link
      slug
      title
      uri
      content
      tags(first: 50) {
        nodes {
          name
          uri
        }
      }
      categories {
        nodes {
          name
        }
      }
      date
      author {
        node {
          name
        }
      }
    }
  }
}


*/

Component.variables = ({ databaseId }, ctx) => {
  const language = "ES";
  return {
    databaseId,
    headerLocation: MENUS.PRIMARY_LOCATION,
    footerLocation: MENUS.FOOTER_LOCATION,
    asPreview: ctx?.asPreview,
    language,
  };
};