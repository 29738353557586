// // firebase.js
// import firebase from 'firebase/app';
// import 'firebase/database';

// const firebaseConfig = {
//     apiKey: "AIzaSyA18c88E9cxr2cs8HiN2HUpxKDqC6IMHcM",
//     authDomain: "cinterfor-12dca.firebaseapp.com",
//     databaseURL: "https://cinterfor-12dca-default-rtdb.firebaseio.com",
//     projectId: "cinterfor-12dca",
//     storageBucket: "cinterfor-12dca.appspot.com",
//     messagingSenderId: "118154132957",
//     appId: "1:118154132957:web:38f87387cc4bf29c9d4a34",
//     measurementId: "G-34S0NYRYK6"
// };

// // const database = initializeApp(firebaseConfig);

// if (!firebase.apps.length) {
//     firebase.initializeApp(firebaseConfig);
// } else {
//     firebase.app(); // Retorne a instância existente do Firebase
// }
  
// const database = firebase.database();
// export { database, firebase };


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA18c88E9cxr2cs8HiN2HUpxKDqC6IMHcM",
  authDomain: "cinterfor-12dca.firebaseapp.com",
  databaseURL: "https://cinterfor-12dca-default-rtdb.firebaseio.com",
  projectId: "cinterfor-12dca",
  storageBucket: "cinterfor-12dca.appspot.com",
  messagingSenderId: "118154132957",
  appId: "1:118154132957:web:38f87387cc4bf29c9d4a34",
  measurementId: "G-34S0NYRYK6"
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export {database}


// const analytics = getAnalytics(app);