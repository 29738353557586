import '../faust.config';
import React from 'react';
import { useRouter } from 'next/router';
import { FaustProvider } from '@faustwp/core';
import '@faustwp/core/dist/css/toolbar.css';
import '../styles/global.scss';
import Analytics from '../components/Analytics/Analytics'
import { ApolloClient, InMemoryCache, HttpLink, ApolloProvider } from '@apollo/client'

const client = new ApolloClient({
  link: new HttpLink({
    uri: '/graphql',
  }),
  cache: new InMemoryCache(),
});

export default function MyApp({ Component, pageProps }) {
  const router = useRouter();

  return (
    <FaustProvider pageProps={pageProps}>
      <ApolloProvider client={client}>
        <Component {...pageProps} key={router.asPath} />
      </ApolloProvider>
      <Analytics
        id="gtag-senac4.0"
        trakingID={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_4}
      />
    </FaustProvider>
  );
}
