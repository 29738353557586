import { gql } from '@apollo/client';
import Image from 'next/image';
export default function FeaturedImage({
  image,
  width,
  height,
  className,
  priority,
  layout,
  ...props
}) {
  const src = (image?.sourceUrl) ? image?.sourceUrl : (image.mediaItemUrl) ? image.mediaItemUrl : '';
  const { altText } = image || '';

  width = width ? width : image?.mediaDetails?.width;
  height = height ? height : image?.mediaDetails?.height;
  layout = layout ?? 'fill';

  return src && width && height ? (
    <figure className={className}>
      <Image
        // src={src}
        src={src.replace(`${process.env.NEXT_PUBLIC_WORDPRESS_URL}/wp-content/uploads/`, '/wp-content/uploads/')}
        alt={altText}
        layout={layout}
        width={width}
        height={height}
        priority={priority}
        {...props}
      />
    </figure>
  ) : null;
}

FeaturedImage.fragments = {
  entry: gql`
    fragment FeaturedImageFragment on NodeWithFeaturedImage {
      featuredImage {
        node {
          id
          sourceUrl
          mediaItemUrl
          altText
          mediaDetails {
            width
            height
          }
        }
      }
    }
  `,
};
