import className from 'classnames/bind';
import Image from 'next/image'
import { Heading, PostInfo, Container, FeaturedImage } from '../../components';
import styles from './SocialShare.module.scss';

let cx = className.bind(styles);

export default function SocialShare({ url, text, className }) {
  // const hasText = title || date || author;

  return (
    <div className={cx([className])}>
      <ul>
        <li>
          <a href={`http://www.linkedin.com/shareArticle?mini=false&url=${url}`} target="_blank" title="Ícone LinkedIn">
            <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.6666 14.9907V21.6573M10.6666 10.9907V11.004M15.9999 21.6573V14.9907M21.3333 21.6573V17.6573C21.3333 16.9501 21.0523 16.2718 20.5522 15.7717C20.0521 15.2716 19.3738 14.9907 18.6666 14.9907C17.9593 14.9907 17.2811 15.2716 16.781 15.7717C16.2809 16.2718 15.9999 16.9501 15.9999 17.6573M5.33325 8.32401C5.33325 7.61676 5.6142 6.93849 6.1143 6.43839C6.6144 5.93829 7.29267 5.65734 7.99992 5.65734H23.9999C24.7072 5.65734 25.3854 5.93829 25.8855 6.43839C26.3856 6.93849 26.6666 7.61676 26.6666 8.32401V24.324C26.6666 25.0313 26.3856 25.7095 25.8855 26.2096C25.3854 26.7097 24.7072 26.9907 23.9999 26.9907H7.99992C7.29267 26.9907 6.6144 26.7097 6.1143 26.2096C5.6142 25.7095 5.33325 25.0313 5.33325 24.324V8.32401Z" stroke="#2C40EC" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </a>
        </li>

        <li>
          <a href={`https://www.facebook.com/sharer.php?u=${url}&text=${text}`} target="_blank" title="Ícone Facebook">
            <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.33325 13.6573V18.9907H13.3333V28.324H18.6666V18.9907H22.6666L23.9999 13.6573H18.6666V10.9907C18.6666 10.637 18.8071 10.2979 19.0571 10.0479C19.3072 9.79781 19.6463 9.65734 19.9999 9.65734H23.9999V4.32401H19.9999C18.2318 4.32401 16.5361 5.02638 15.2859 6.27663C14.0356 7.52687 13.3333 9.22256 13.3333 10.9907V13.6573H9.33325Z" stroke="#2C40EC" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </a>
        </li>

        <li>
          <a href={`https://twitter.com/intent/tweet?url=${url}&text=${text}`} target="_blank" title="Ícone Twitter">
            <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M29.3332 5.67069C27.9998 6.32402 26.6932 6.58936 25.3332 6.99069C23.8385 5.30402 21.6225 5.21069 19.4932 6.00802C17.3638 6.80536 15.9692 8.75469 15.9998 10.9907V12.324C11.6732 12.4347 7.81984 10.464 5.33317 6.99069C5.33317 6.99069 -0.242829 16.9014 10.6665 21.6574C8.1705 23.32 5.68117 24.4414 2.6665 24.324C7.07717 26.728 11.8838 27.5547 16.0452 26.3467C20.8185 24.96 24.7412 21.3827 26.2465 16.024C26.6956 14.3942 26.9185 12.7105 26.9092 11.02C26.9092 10.688 28.9225 7.32402 29.3332 5.66936V5.67069Z" stroke="#2C40EC" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </a>
        </li>

      </ul>

    </div>
  );
}
