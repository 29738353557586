import { database } from '../../firebase';

import classNames from 'classnames/bind';
import Image from 'next/image';

import styles from './LikeButton.module.scss';
let cx = classNames.bind(styles);
import Like from '../../public/svg/like.svg';
import { useState } from 'react';
import { ref, update, onValue } from "firebase/database";
import { useEffect } from 'react';




export default function LikeButton(props) {
  const [count, setCount] = useState(0);
  const [gosteiClicado, setGosteiClicado] = useState(false);
  // const [liked, setLiked] = useState(false);

  useEffect(() => {
    // Verifique o localStorage quando o componente for montado
    const gosteiClicadoLocalStorage = localStorage.getItem(`gosteiClicado-${props.id}`) === 'true';
    setGosteiClicado(gosteiClicadoLocalStorage);
  }, []);


  const handleLike = () => {
    
    if (localStorage.getItem(`gosteiClicado-${props.id}`) !== 'true') {
      setCount((event) => event + 1);
      const postData = {
        title: props.title,
        count: count + 1
      };
      const updates = {};
      updates[`/posts/${props.id}`] = postData;
      
      setGosteiClicado(true);
      localStorage.setItem(`gosteiClicado-${props.id}`, 'true');

      return update(ref(database), updates);
  
    } else {
      setCount((event) => event - 1);
      const postData = {
        title: props.title,
        count: count - 1
      };
      const updates = {};
      updates[`/posts/${props.id}`] = postData;

      setGosteiClicado(false);
      localStorage.setItem(`gosteiClicado-${props.id}`, 'false');
    
      return update(ref(database), updates);
    }
  
  };

  useEffect(() => {
    const countRef = ref(database, `posts/${props.id}`);
    onValue(countRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setCount(data?.count);
      }
    });
  }, [count]);


  return (
    <button className={cx('like')} onClick={handleLike}>
      <Image
        src={Like}
        alt="Like icon"
      />
      <span>{count}</span>
    </button>
  );
}


