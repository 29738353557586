import classNames from 'classnames/bind';
import styles from './CardPosts.module.scss';
import Image from 'next/image';
import Link from 'next/link';
let cx = classNames.bind(styles);
import { AcfFields } from '../AcfFields';


//Icons
import Tag from '../../public/svg/tag.svg';
import TargetArrow from '../../public/svg/target-arrow.svg';
import UsersGroup from '../../public/svg/users-group.svg';
import Pin from '../../public/svg/map-pin.svg';
import Circles from '../../public/svg/circles.svg';
import Clock from '../../public/svg/clock-hour-4.svg';
import Git from '../../public/svg/git-branch.svg';
import cardIcon from '../../public/svg/iconCard.svg';
import { LikeButton } from '../LikeButton';
import { useEffect, useState } from 'react';

import xButton from '../../public/svg/xCards.svg'

export default function CardPosts(props) {
  const [tipoCard, setTipoCard] = useState('principal');
  const [minimoTempo, setMinimoTempo] = useState()
  const [maximoTempo, setMaximoTempo] = useState()

  const handleChangeType = () => {
    if (tipoCard == "principal") {
      setTipoCard('secundário')
    } else {
      setTipoCard('principal')
    }
  }

  useEffect(() => {
    const { tempoMinimo, tempoMaximo } = props?.acf || {};
    setMinimoTempo(tempoMinimo ? `${tempoMinimo}min` : 'Sem limite');
    setMaximoTempo(tempoMaximo && tempoMaximo !== 500 ? `${tempoMaximo}min` : 'Sem limite');
  }, []);

  // console.log('***', props);

  if (tipoCard == 'principal') {
    return (
      <div onClick={handleChangeType} className={cx('cardPrincipal', props.single ? 'single' : null)} >
        <div className={cx('containerIcon')}>
          <Image
            src={props.img.replace(`${process.env.NEXT_PUBLIC_WORDPRESS_URL}wp-content/uploads`, '/wp-content/uploads/')}
            width={290}
            height={196}
            className={cx('logo')}
            alt={props.title}
          />
        </div>
        <div className={cx('footerCard')}>
          <h2>{props.title}</h2>
        </div>
      </div>
    );

  } else {

    return (
      <div className={cx('containerCard', props.single ? 'single' : null)} >
        <header onClick={handleChangeType}>
          <Image
            src={props.img}
            alt=""
            width={126}
            height={105}
            className={cx('imagePrincipal')}
          />

          <h2 onClick={handleChangeType}>{props.title}</h2>

          {/* <Image src={xButton}  /> */}

          <svg onClick={handleChangeType} className={cx('xButtonClose')} width="24" height="27" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 2.5L2 6.5M2 2.5L6 6.5" stroke="#95CE4B" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>

        </header>

        <div className={cx('content')} onClick={handleChangeType}>

          <div dangerouslySetInnerHTML={{ __html: props.description }} className={cx('description')} />
          {/* <p className={cx('description')}>{props.description}</p> */}

          <div className={cx('acfData')}>
            <AcfFields
              title={props.classificacao}
              image={Tag}
            />

            <AcfFields
              title={props.acf?.finalidadesPedagogicas}
              image={TargetArrow}
            />

            <AcfFields
              title={`${props.acf?.participantesMinimo == null ? 'Sem limite' : props.acf?.participantesMinimo} - ${props.acf?.participantesMaximo == 300 ? 'Sem limite' : props.acf?.participantesMaximo}`}
              image={UsersGroup}
            />

            <AcfFields
              title={`${minimoTempo} - ${maximoTempo}`}
              image={Clock}
            />

            <AcfFields
              title={props.acf?.formato}
              image={Pin}
            />

            <AcfFields
              title={props.acf?.tipodeatividade}
              image={Circles}
            />

            <AcfFields
              title={`Mínimo de ${props.acf?.facilitacao}`}
              image={Git}
            />
          </div>
        </div>

        <div className={cx('footerCard')}>

          <Link href={`${props.uri}/#title-single`}>
            <a target='_blank'>
              <button className={cx('saibaMais')}>{props.language == "ES" ? "Sepa mas" : "Saiba mais"}</button>
            </a>
          </Link>
          <LikeButton key={props.id} id={props.id} title={props.title} />

        </div>
      </div>
    );
  }
}

