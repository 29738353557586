import classNames from 'classnames/bind';
import { useQuery, gql } from '@apollo/client';
import Link from 'next/link';
import styles from './Header.module.scss';
import FilterIcon from '../../public/svg/icon-filter.svg';
import SearchIcon from '../../public/svg/icon-search.svg';
import GlobeIcon from '../../public/svg/icon-globe.svg';
import MenuIcon from '../../public/svg/menu.svg';
import Educx from '../../public/svg/logo.svg';
import Image from 'next/image';
import Head from 'next/head'
import { useRouter } from 'next/router';
let cx = classNames.bind(styles);
import iconX from '../../public/svg/icon-x.svg';
import flagBR from '../../public/svg/BR.svg';
import flagES from '../../public/svg/ES.svg';
import flagUS from '../../public/svg/US.svg';
import { useEffect, useState } from 'react';

const GET_TRANSLATE = gql`
query post ($databaseId: ID!, $language: LanguageCodeEnum!){
  post (id: $databaseId) {
    translation(language: $language) {
      id
      link
      slug
      uri
      language {
        code
      }
    }
  }
}
`


export default function Header(props) {
  const router = useRouter();

  const [searchInput, setSearchInput] = useState();
  const [modalActive, setModalActive] = useState(false);
  const [language, setLanguage] = useState("PT");
  const [languageTranslate, setLanguageTranslate] = useState();

  const changeLaguage = (e) => {
    const newLanguage = e.target.value;
    setLanguage(newLanguage);
    setLanguageTranslate(newLanguage);

    handleIconFlag()

    props.changeLaguageGeneral(e.target.value);
  }


  const { data } = useQuery(GET_TRANSLATE, {
    variables: {
      databaseId: props.idFicha,
      language: languageTranslate,
    },
    onCompleted: (data) => {
      handleIconFlag()
    }
  });


  const handleModal = () => {
    if (modalActive == false) {
      setModalActive(true)
    } else {
      setModalActive(false)
    }
  }

  useEffect(() => {
    if (props.tipoPagina == "sobre") {
      setLanguage(props.lang)
    }
  })

  const handleIconFlag = () => {
    if (data?.post.translation.uri) {
      router.push(data?.post?.translation?.uri);
    }
  }

  const handleSearch = (e) => {
    router.push(`/?search=${searchInput}`);
  };

  const [searchTerm, setSearchTerm] = useState('');

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    props.onFilterChange(value);
  };



  const changeLaguageHome = (e) => {
    setLanguage(e.target.value)
    props.changeLaguageGeneral(e.target.value);
  }

  // useEffect(() => {
  //   if (props.tipoPagina == "single") {
  //   }
  //   // quebrou a lógica do search da single para home, pensar em algo..
  //   // else {
  //   //   router.push(`${language == "PT" ? "/" : `/${language.toLowerCase()}`}`)
  //   // }
  // }, [language])

  if (props.tipoPagina == "single") {
    return (
      <>
        <Head>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
          <link href="https://fonts.googleapis.com/css2?family=Inter:wght@600;700&display=swap" rel="stylesheet" />
        </Head>
        <header className={cx('component')}>
          <div className={cx('headerContainer')}>
            <div className={cx('logo')}>
              <Link
                href="/"
              >
                <Image
                  priority
                  src={Educx}
                  alt="Cubus Logo"
                />
              </Link>
            </div>

            <div className={cx('inputContainer')}>
              <Image
                priority
                src={SearchIcon}
                alt="Search Icon"
              />
              <input
                type="text"
                placeholder={props.language == "ES" ? "Buscar" : "Pesquisa"}
                className={cx('searchInput')}
                onChange={(e) => setSearchInput(e.target.value)}
                aria-label="Linguagem"
              />

              <Image
                priority
                src={FilterIcon}
                alt="Filter Icon"
                className={cx('iconSearch')}
                onClick={handleSearch}
                onDragEnter={handleSearch}
              />
            </div>

            <div className={cx('links')}>
              <Link href='/'>{props?.language == "ES" ? "Página de Inicio" : "Página Inicial"}</Link>
              <Link href={props?.language == 'ES' ? '/es/sobre-esta-plataforma' : '/sobre-a-plataforma'}>{props?.language == "ES" ? "Sobre esta plataforma" : "Sobre a Plataforma"}</Link>
              <a href={props?.language == "ES" ? "https://cinterfor.vulpecomunicacao.com.br/pdf/Glossario_termos_relevantes_ES.pdf" : "https://cinterfor.vulpecomunicacao.com.br/pdf/Glossario_termos_relevantes.pdf"} target='_blank'>{props?.language == "ES" ? "Glosario" : "Glossário"}</a>

              <div className={cx('globeContainer')}>
                {/* <Image
                  priority
                  src={flagBR}
                  alt="Globe Icon"
                  name="PT"
                  width={40}
                  height={40}
                  onClick={handleIconFlag}
                />
                <Image
                  priority
                  src={flagES}
                  alt="Globe Icon"
                  name="ES"
                  width={40}
                  height={40}
                  onClick={handleIconFlagESfake}
                />
                <Image
                  priority
                  src={flagUS}
                  alt="Globe Icon"
                  name="US"
                  width={40}
                  height={40}
                /> */}
                <select value={props.language} onChange={changeLaguage} aria-label="Idioma">
                  <option value="PT">Português</option>
                  <option value="ES">Español</option>
                  <option value="EN">English</option>
                </select>

              </div>
            </div>

            <div className={cx('menuMobile')}>
              <Image
                priority
                src={MenuIcon}
                alt="Menu Icon"
                className={cx('menuIcon')}
                onClick={handleModal}
              />
            </div>
          </div>


          <nav className={cx('nav')}>
            <ul className={cx('ul')}>
              {/* <li>
                <a href="#main-content">{props.language == "ES" ? "Inicio": "Topo"}</a>
              </li> */}

              <li className={cx('title')}>
                <a>
                  {props?.titleMateria}
                </a>
              </li>

              <li>
                <a href="#objetivos">{props?.language == "ES" ? "Objetivos" : "Objetivos"}</a>
              </li>

              <li>
                <a href="#requisitos">Requisitos</a>
              </li>

              <li>
                <a href="#preparacao">{props?.language == "ES" ? "Procedimientos" : "Procedimentos"}</a>
              </li>

              <li>
                <a href="#aplicacao">{props?.language == "ES" ? "Solicitud" : "Aplicações"}</a>
              </li>

              <li>
                <a href="#exemplo">{props?.language == "ES" ? "Ejemplo" : "Exemplo"}</a>
              </li>

              <li>
                <a href="#dicas">{props?.language == "ES" ? "Consejos para actuar en línea" : "Dicas para formato on-line"}</a>
              </li>

              <li>
                <a href="#referencias">{props?.language == "ES" ? "Referencias" : "Referências"}</a>
              </li>

              {/* <li>
                <a href="#palavrasChave">{props.language == "ES" ? "Palabras clave": "Palavras-chave"}</a>
              </li>  */}

              {/* <li>
                <a>Outras Ferramentas</a>
              </li>  */}
            </ul>
          </nav>
        </header>
        <div className={cx(['modalMenuNav', modalActive === true ? 'active ' : ''])}>
          <div className={cx('containerXMenu')}>
            <Image
              priority
              src={iconX}
              alt="Botão de fechar o menu"
              onClick={(e) => setModalActive(false)}
            />

          </div>
          <hr />
          <div className={cx('p-20')}>
            <div className={cx('inputContainer')}>
              <Image
                priority
                src={SearchIcon}
                alt="Search Icon"
              />
              <input
                type="text"
                placeholder={props?.language == "ES" ? "Buscar" : "Pesquisa"}
                className={cx('searchInput')}
                onChange={(e) => setSearchInput(e.target.value)}
                aria-label="Busca idioma"
              />

              <Image
                priority
                src={FilterIcon}
                alt="Filter Icon"
                className={cx('iconSearch')}
                onClick={handleSearch}
              />
            </div>
          </div>

          <div className={cx('navMenu')}>
            <Link href='/' className={cx('links')}>
              <a>{props?.language == "ES" ? "Inicio" : "Início"}</a>
            </Link>

            <Link href={props?.language == 'ES' ? '/es/sobre-esta-plataforma' : '/sobre-a-plataforma'} className={cx('links')}>
              <a>{props?.language == "ES" ? "Sobre esta plataforma" : "Sobre a Plataforma"}</a>
            </Link>

            <a href={props?.language == "ES" ? "https://cinterfor.vulpecomunicacao.com.br/pdf/Glossario_termos_relevantes_ES.pdf" : "https://cinterfor.vulpecomunicacao.com.br/pdf/Glossario_termos_relevantes.pdf"} className={cx('links')} target='_blank'>{props.language == "ES" ? "Glosario" : "Glossário"}</a>
          </div>

        </div>
      </>
    );
  } else {
    return (
      <>
        <Head>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
          <link href="https://fonts.googleapis.com/css2?family=Inter:wght@600;700&display=swap" rel="stylesheet" />
        </Head>
        <header className={cx('component')}>
          <div className={cx('headerContainer')}>
            <div className={cx('logo')}>
              <Link href='/' passHref>
                <a>
                  <Image
                    priority
                    src={Educx}
                    alt="Logo Cubus"
                  />
                </a>
              </Link>
            </div>

            <div className={cx('inputContainer')}>
              <Image
                priority
                src={SearchIcon}
                alt="Search Icon"
              />
              <input
                type="text"
                placeholder={language == "ES" ? "Buscar" : "Pesquisa"}
                className={cx('searchInput')}
                value={searchTerm}
                onChange={handleInputChange}
                aria-label="Busca Idioma"
              />

              {/* <Image
                priority
                src={FilterIcon}
                alt="Filter Icon"
                value={searchTerm}
                onClick={handleInputChange}
              /> */}
            </div>

            <div className={cx('links')}>
              <Link href='/'>{language == "ES" ? "Página de Inicio" : "Página Inicial"}</Link>
              <Link href={language == 'ES' ? '/es/sobre-esta-plataforma' : '/sobre-a-plataforma'}>{language == "ES" ? "Sobre esta plataforma" : "Sobre a Plataforma"}</Link>
              <a href={language == "ES" ? "https://cinterfor.vulpecomunicacao.com.br/pdf/Glossario_termos_relevantes_ES.pdf" : "https://cinterfor.vulpecomunicacao.com.br/pdf/Glossario_termos_relevantes.pdf"} target='_blank'>{language == "ES" ? "Glosario" : "Glossário"}</a>

              <div className={cx('globeContainer')}>
                {/* <Image
                  priority
                  src={flagBR}
                  alt="Globe Icon"
                  name="PT"
                  width={40}
                  height={40}
                  onClick={changeLaguage}
                />
                <Image
                  priority
                  src={flagES}
                  alt="Globe Icon"
                  name="ES"
                  width={40}
                  height={40}
                  onClick={changeLaguage}
                />
                <Image
                  priority
                  src={flagUS}
                  alt="Globe Icon"
                  name="US"
                  width={40}
                  height={40}
                  onClick={changeLaguage}
                /> */}
                <select value={language} onChange={changeLaguageHome} aria-label="Idioma">
                  <option value="PT">Português</option>
                  <option value="ES">Español</option>
                  <option value="EN">English</option>
                </select>
              </div>
            </div>

            <div className={cx('menuMobile')}>
              <Image
                priority
                src={MenuIcon}
                alt="Menu Icon"
                className={cx('menuIcon')}
                onClick={handleModal}
              />
            </div>
          </div>
        </header>

        <div className={cx(['modalMenuNav', modalActive === true ? 'active ' : ''])}>
          <div className={cx('containerXMenu')}>
            <Image
              priority
              src={iconX}
              alt="Botão de fechar o menu"
              onClick={(e) => setModalActive(false)}
            />

          </div>
          <hr />
          <div className={cx('p-20')}>
            <div className={cx('inputContainer')}>
              <Image
                priority
                src={SearchIcon}
                alt="Search Icon"
              />
              <input
                type="text"
                placeholder={language == "ES" ? "Buscar" : "Pesquisa"}
                className={cx('searchInput')}
                onChange={(e) => setSearchInput(e.target.value)}
                aria-label="Busca idioma"
              />

              <Image
                priority
                src={FilterIcon}
                alt="Filter Icon"
                className={cx('iconSearch')}
                onClick={handleSearch}
              />
            </div>
          </div>

          <div className={cx('navMenu')}>
            <Link href='/' className={cx('links')}>
              <a>{language == "ES" ? "Inicio" : "Início"}</a>
            </Link>

            <Link href={language == 'ES' ? '/es/sobre-esta-plataforma' : '/sobre-a-plataforma'} className={cx('links')}>
              <a>{language == "ES" ? "Sobre esta Plataforma" : "Sobre a Plataforma"}</a>
            </Link>

            <a href={language == "ES" ? "https://cinterfor.vulpecomunicacao.com.br/pdf/Glossario_termos_relevantes_ES.pdf" : "https://cinterfor.vulpecomunicacao.com.br/pdf/Glossario_termos_relevantes.pdf"} className={cx('links')} target='_blank'>{language == "ES" ? "Glosario" : "Glossário"}</a>
          </div>

        </div>
      </>
    );
  }
}
